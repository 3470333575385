import React from 'react';
import { Button, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import logo from './../../../assets/images/zay-corporation.svg';

import './NavigationBar.css';

const NavigationBar = () => {
    return (
        <>
            <div className="row d-block d-md-none">
                <div className="m-0 p-0 col-md-12 d-md-flex justify-content-center">
                    <Navbar className="navbar-responsive-bg py-2 mb-5 mb-md-0" bg="" expand="md" >

                        <Link to="/home" className="logo-style">
                            <img
                                src={logo}
                                width="60"
                                height="60"
                                className="d-block align-center logo-size"
                                alt="zayCo-logo"
                            />
                        </Link>
                        <div className="d-block d-md-none nav-user-icon-border ms-auto me-2">
                            <div className="nav-user-icon-bg text-white px-2 py-1 rounded me-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-activity"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                            </div>
                        </div>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="ms-auto me-0 py-4 py-md-0 bg-white">
                            <Nav className="me-md-5 d-md-flex justify-content-between align-items-center" style={{ fontWeight: 600 }}>
                                <Link to="/home" className="nav-item-borders nav-item nav-link text-dark">Home</Link>
                                {/* <Link to="/services" className="nav-item-borders nav-item nav-link text-dark">Services</Link> */}
                                {/* <Link to="/about" className="nav-item-borders nav-item nav-link text-dark">About</Link> */}
                                <NavDropdown title="Services" id="basic-nav-dropdown" className="nav-item-borders" style={{ color: '#000' }}>
                                    <NavDropdown.Item >
                                        <NavLink to={"/ui_ux_design"} className="nav-item nav-link text-dark">UI/UX Design</NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item >
                                        <NavLink to={"/web_development"} className="nav-item nav-link text-dark">Web Development</NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item >
                                        <NavLink to={"/app_development"} className="nav-item nav-link text-dark">App Development</NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item >
                                        <NavLink to={"/software_development"} className="nav-item nav-link text-dark">Software Development</NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item >
                                        <NavLink to={"/enterprise_solutions"} className="nav-item nav-link text-dark">Enterprise Solutions</NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item >
                                        <NavLink to={"/managed_it_services"} className="nav-item nav-link text-dark">Managed IT Services</NavLink>
                                    </NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title="About" id="basic-nav-dropdown" className="nav-item-borders" style={{ color: '#000' }}>
                                    <NavDropdown.Item >
                                        <NavLink to={"/about_us"} className="nav-item nav-link text-dark">About Us</NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item >
                                        <NavLink to={"/life_at_ZayCo"} className="nav-item nav-link text-dark">Life at Zayco</NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item >
                                        <NavLink to={"/career"} className="nav-item nav-link text-dark">Career</NavLink>
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <Link to="/success" className="nav-item-borders nav-item nav-link text-dark">Success</Link>
                                <Link to="/contact" className="nav-item-borders nav-item nav-link text-dark">Contact</Link>
                            </Nav>
                            <Nav>
                                <Button className="ms-auto me-0 d-none d-md-block" variant="warning" style={{ width: '150px', fontWeight: '600' }}>Log In</Button>{' '}
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>

            {/* custom nav for desktop screen*/}
            <nav className="shadow row d-none d-md-block bg-white d-md-flex justify-content-start align-items-start fixed-top">
                <div className="col-md-2 navigation-logo d-md-flex justify-content-center align-items-center">
                    <Link to="/">
                        <img
                            src={logo}
                            width="100"
                            height="100"
                            className="d-block align-center logo-size navigation-logo-position"
                            alt="zayCo-logo"
                        /></Link>
                </div>
                <div className="m-0 p-0 col-md-8">
                    <div className="row">
                        {/* <div className="col-md-3 navigation-logo">
                            <Link to="/">
                                <img
                                    src={logo}
                                    width="100"
                                    height="100"
                                    className="d-block align-center logo-size navigation-logo-position"
                                    alt="zayCo-logo"
                                /></Link>
                        </div> */}
                        <div className="col-md-9 d-md-flex justify-content-end align-items-center" style={{ fontWeight: 600 }}>
                            <Link to="/home" className="nav-item nav-link text-dark">Home</Link>
                            {/* <Link to="/services" className="nav-item nav-link text-dark">Services</Link> */}
                            {/* <Link to="/about" className="nav-item nav-link text-dark">About</Link> */}
                            <NavDropdown title="Services" id="basic-nav-dropdown" className="" style={{ color: '#000' }}>
                                <NavDropdown.Item >
                                    <NavLink to={"/ui_ux_design"} className="nav-item nav-link text-dark">UI/UX Design</NavLink>
                                </NavDropdown.Item>
                                <NavDropdown.Item >
                                    <NavLink to={"/web_development"} className="nav-item nav-link text-dark">Web Development</NavLink>
                                </NavDropdown.Item>
                                <NavDropdown.Item >
                                    <NavLink to={"/app_development"} className="nav-item nav-link text-dark">App Development</NavLink>
                                </NavDropdown.Item>
                                <NavDropdown.Item >
                                    <NavLink to={"/software_development"} className="nav-item nav-link text-dark">Software Development</NavLink>
                                </NavDropdown.Item>
                                <NavDropdown.Item >
                                    <NavLink to={"/enterprise_solutions"} className="nav-item nav-link text-dark">Enterprise Solutions</NavLink>
                                </NavDropdown.Item>
                                <NavDropdown.Item >
                                    <NavLink to={"/managed_it_services"} className="nav-item nav-link text-dark">Managed IT Services</NavLink>
                                </NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown title="About" id="basic-nav-dropdown" className="" style={{ color: '#000' }}>
                                <NavDropdown.Item >
                                    <NavLink to={"/about_us"} className="nav-item nav-link text-dark">About Us</NavLink>
                                </NavDropdown.Item>
                                <NavDropdown.Item >
                                    <NavLink to={"/life_at_ZayCo"} className="nav-item nav-link text-dark">Life at Zayco</NavLink>
                                </NavDropdown.Item>
                                <NavDropdown.Item >
                                    <NavLink to={"/career"} className="nav-item nav-link text-dark">Career</NavLink>
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Link to="/success" className="nav-item nav-link text-dark">Success</Link>
                            <Link to="/contact" className="nav-item nav-link text-dark">Contact</Link>
                        </div>
                        <div className="col-md-3 d-md-flex justify-content-center align-items-end">
                            {/* <div className="row"> */}
                            {/* <div className="col-md-12 d-md-flex justify-content-center"> */}
                            <Button className="ms-auto me-0 my-2 d-none d-md-block" variant="warning" style={{ width: '40%', fontWeight: '600' }}>Log In</Button>{' '}
                            {/* </div> */}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default NavigationBar;