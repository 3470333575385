import React from 'react';
// import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import notFound404 from '../../assets/images/error/404.png';
// import notFoundMobile404 from '../../assets/images/error/404-mobile.jpg';
import './NotFound404.css';

const Notfound404 = () => {
    return (
        <>
            <div className='row not-found d-flex justify-content-center align-items-center'>
                <div className="col-12 col-md-12">
                    {/* <img src={notFound404} alt="error" className="d-none d-md-block img-fluid vh-100 vw-100" />
                    <img src={notFoundMobile404} alt="error" className="d-block d-md-none img-fluid vh-100 vw-100" /> */}
                    <Link to="/" className="d-flex justify-content-center align-items-center text-decoration-none">
                        <div className="back-button" style={{ marginTop: '380px' }}>
                            <button className="shadow px-5 py-md-2 py-2 px-md-5 secondaryButton" style={{ width: "100%", fontWeight: 600 }}>Back to Home</button>
                        </div>
                    </Link>
                </div>
                {/* <Button variant="outlined" className="fw-bold text-uppercase fs-6 fs-md-4 px-md-5 px-3 rounded" style={{ backgroundColor: 'red', color: 'white' }}>Back to Home</Button> */}
            </div>
        </>
    );
};

export default Notfound404;