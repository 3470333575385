import React, { useState } from 'react';
// import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { FaMinus, FaPlus } from 'react-icons/fa';
import Footer from '../Shared/Footer/Footer';
import NavigationBar from '../Shared/NavigationBar/NavigationBar';
import './UiUxPage.css';

import howDoWeWork from '../../assets/images/how-we-work.png';

import project1 from '../../assets/images/featured-services/Service Page - UI/project1.png';
import project2 from '../../assets/images/featured-services/Service Page - UI/project2.png';

import subProject1 from '../../assets/images/featured-services/Service Page - UI/sub-project1.png';

import featuredService1 from '../../assets/images/featured-services/Service Page - UI/featured-service-1.png';
import featuredService2 from '../../assets/images/featured-services/Service Page - UI/featured-service-2.png';
import featuredService3 from '../../assets/images/featured-services/Service Page - UI/featured-service-3.png';

import ServicesTypes from '../Shared/ServicesTypes/ServicesTypes';
import { Link } from 'react-router-dom';

const UiUxPage = () => {
    const [clicked, setClicked] = useState("0");

    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked("0");
        }
        setClicked(index);
    };

    const projects = [
        {
            id: 1,
            image: project1,
            title: `Designing Intuitive Training Modules`,
            description: `With an e-learning and training tool that brings an organisation’s workforce up to speed, we built an Online Training Module to help employees track performances.`,
        },
        {
            id: 2,
            image: project2,
            title: `Design For Expense Tracker App`,
            description: `We have designed an expense tracker app that functions as a simple money tracker for users to monitor, maintain and stay on top of their budget and expenses.`,
        },
    ];

    const subProjects = [
        {
            id: 1,
            title: 'Mobile App Prototype For Social E-Commerce App',
            image: `${subProject1}`,
            description: `With an e-learning and training tool that brings an organisation’s workforce up to speed, we built an Online Training Module to help employees track performances and record the qualifications of their training programs.`,
        },
        {
            id: 2,
            title: 'Mobile App Prototype For Social E-Commerce App',
            image: `${subProject1}`,
            description: `With an e-learning and training tool that brings an organisation’s workforce up to speed, we built an Online Training Module to help employees track performances and record the qualifications of their training programs.`,
        },
        {
            id: 3,
            title: 'Mobile App Prototype For Social E-Commerce App',
            image: `${subProject1}`,
            description: `With an e-learning and training tool that brings an organisation’s workforce up to speed, we built an Online Training Module to help employees track performances and record the qualifications of their training programs.`,
        },
    ];

    const featuredServices = [
        {
            id: 1,
            image: featuredService1,
            title: `Maximising Lead Generations`,
            description: `Lead generation is the goal, and we design your UX/UI from scratch with the perfect blend of aesthetics and technology t maximise leads.`,
        },
        {
            id: 2,
            image: featuredService2,
            title: `Versatile Knowledge Of Businesses`,
            description: `With decades of experience in digital product design for various business domains, we blend our versatile knowledge to create a success story.`,
        },
        {
            id: 3,
            image: featuredService3,
            title: `Customer’s Customer First`,
            description: `We believe in the “customer’s customer-first” approach when designing every digital product pixel to meet your customer’s expectations.`,
        },
    ];

    const faqs = [
        {
            id: 1,
            question: `Why UX/UI?`,
            answer:
                `Without a strategically designed UX/UI, brands fail to engage customers online. So, UX/UI design is essential for your digital product to succeed.`,
        },
        {
            id: 2,
            question: `How do we design?`,
            answer:
                `Without a strategically designed UX/UI, brands fail to engage customers online. So, UX/UI design is essential for your digital product to succeed.`,
        },
        {
            id: 3,
            question: `How much does it cost?`,
            answer:
                `Without a strategically designed UX/UI, brands fail to engage customers online. So, UX/UI design is essential for your digital product to succeed.`,
        },
        {
            id: 4,
            question: `What are the tools you use for designs?`,
            answer:
                `Without a strategically designed UX/UI, brands fail to engage customers online. So, UX/UI design is essential for your digital product to succeed.`,
        },
        {
            id: 5,
            question: `What is needed from me to get started?`,
            answer:
                `Without a strategically designed UX/UI, brands fail to engage customers online. So, UX/UI design is essential for your digital product to succeed.`,
        },
        {
            id: 6,
            question: `How Zay Co’s UX/UI Design Solution Helps?`,
            answer:
                `Without a strategically designed UX/UI, brands fail to engage customers online. So, UX/UI design is essential for your digital product to succeed.`,
        },
        {
            id: 7,
            question: `Would you like to talk to us in person?`,
            answer:
                `Without a strategically designed UX/UI, brands fail to engage customers online. So, UX/UI design is essential for your digital product to succeed.`,
        },

    ];

    return (
        <>
            <NavigationBar />

            {/* banner section */}
            <div className="row mt-md-5 ui-ux-banner">
                <div className=" py-5 row col-12 col-md-12 d-flex flex-column justify-content-center align-items-center text-center text-white">
                    <div className="row col-11 col-md-8">
                        <div className="col-12 col-md-12">
                            <h5 className="fw-bold d-md-none d-block">Zayco UX/UI Design Solution</h5>
                            <h4 className="fw-bold d-none d-md-block">Zayco UX/UI Design Solution</h4>
                        </div>
                        <div className="col-12 col-md-12">
                            <h3 className="fw-bold d-md-none d-block">Maximize Your Business With<br /> Engaging UX/UI</h3>
                            <h1 className="fw-bold d-none d-md-block">Maximize Your Business With<br /> Engaging UX/UI</h1>
                        </div>
                        <div className="col-12 col-md-12 my-4">
                            <p className="fs-5 d-none d-md-block">We create engaging designs that improve human-screen interactions and help build your vision better. </p>
                            <p className="d-md-none d-block">We create engaging designs that improve human-screen interactions and help build your vision better. </p>
                        </div>
                        <div className="col-12 col-md-12">
                            <button className="secondaryButton px-4 py-3 px-md-5 py-md-3 fw-bold" style={{ fontSize: '16px' }}>Got a project idea? Let's talk!</button>
                        </div>
                    </div>
                </div>
            </div>

            <ServicesTypes />

            {/* how do we work */}
            <div className="row d-flex justify-content-center align-items-center my-5 py-5 py-md-5 my-md-5" style={{ backgroundColor: '#F5F5F5' }}>
                <div className="col-10 col-md-6 px-md-3 py-md-5">
                    <img className="img-fluid" src={howDoWeWork} alt="howDoWeWork" />
                </div>
            </div>

            {/* projects section */}
            <div className="row d-flex justify-content-center align-items-center my-5">
                <div className="col-11 col-md-8">
                    <div className="row my-4 my-md-5">
                        <div className="col-12 col-md-12">
                            <h2 className="fw-bold text-center">Our Projects</h2>
                        </div>
                    </div>
                    <div className="row g-0 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-start mb-md-5">
                        {
                            projects.map((project, index) =>
                                <div key={project.id} className="col-12 col-md-6 gx-5 mb-5">
                                    <div className="row shadow">
                                        <div className="col-12 col-md-12">
                                            <img src={project.image} alt="feature-project" className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-12 col-md-12">
                                            <p className="text-secondary text-uppercase">Project</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-12">
                                            <h5 className="">{project.title}</h5>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 col-md-12">
                                            <p className="ternaryText">{project.description}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    {/* sub-projects section */}

                    {/* single sub-project */}
                    {
                        subProjects?.map((singleSubProject, index) =>
                            <div key={singleSubProject.id} className="row mt-3 mb-4 py-3 rounded responsive-sub-project" style={{ backgroundColor: '#F5F5F5' }}>
                                <div className="row col-12 col-md-12 px-2 py-3 px-md-3">
                                    <div className="col-4 col-md-3 bg-white rounded d-flex justify-content-center align-items-center">
                                        <img src={singleSubProject?.image} alt="subProject" className="img-fluid" />
                                    </div>
                                    <div className="col-8 col-md-9 ps-3">
                                        <div className="row p-md-4">
                                            <div className="col-12 col-md-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-12">
                                                        <p className="text-secondary text-uppercase">Project</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-12">
                                                        <h5 className="">{singleSubProject?.title}</h5>
                                                    </div>
                                                </div>
                                                <div className="row my-2 my-md-2">
                                                    <div className="col-12 col-md-12">
                                                        <p className="">{singleSubProject?.description}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-12">
                                                        <Link to="/">
                                                            <button className="bordered-custom-button" style={{ fontWeight: 600 }}>Get Appointment</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>

            {/* featured services */}
            <div className="featured-services row d-flex justify-content-center align-items-center mt-0 my-md-5">
                <div className="col-11 col-md-8">
                    <div className="row">
                        {
                            featuredServices?.map((service, index) =>
                                <div key={service.id} className="col-12 col-md-4 my-4 my-md-0 pb-0">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-6 col-md-3 d-flex justify-content-center align-items-center">
                                            <img src={service.image} alt="featuredService" className="" />
                                        </div>
                                    </div>
                                    <div className="row p-2 mt-3 p-md-3 mt-md-3">
                                        <div className="col-12 col-md-12">
                                            <h6 className="text-center fw-bold">{service.title}</h6>
                                        </div>
                                    </div>
                                    <div className="row px-md-2">
                                        <p className="text-center mobile-responsive-text">{service.description}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            {/* FAQ accordion */}
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-11 col-md-8">
                    <div className="row my-5 my-md-1">
                        <div className="col-12 col-md-12">
                            <h2 className="fw-bold text-center">Common FAQs On UX/UI Design Solutions</h2>
                        </div>
                    </div>
                    <div className="row my-5 my-md-5">
                        <div className="col-12 col-md-12">
                            <ul className="accordion">
                                {
                                    faqs.map((item, index) =>
                                        // <AccordionItem key={index} faq={faq} />
                                        <li key={item.id} className={`accordion_item ${clicked === index ? "active" : ""}`}>
                                            <button className="question button" onClick={() => handleToggle(index)}>
                                                {item.question}
                                                <span className="control">{clicked === index ? <FaMinus style={{ color: '#FFCE45' }} /> : <FaPlus style={{ color: '#03A17B' }} />}</span>
                                            </button>
                                            <div className={`answer_wrapper ${clicked === index ? "open" : ""}`}>
                                                <div className="answer">{item.answer}</div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    );
};

export default UiUxPage;