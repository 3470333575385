import React from 'react';
import { Form } from 'react-bootstrap';
import './Appointment.css';
import appointmentBackgroundImage from './../../../assets/images/appointment-bg.png';

const Appointment = () => {
    return (
        <>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-8 col-11 py-4 px-4 p-md-5 mt-5 text-start" style={{ backgroundImage: `url(${appointmentBackgroundImage})`, backgroundRepeat: '   repeat' }}>
                    <div className="row">
                        <div className="col-12 mb-4">
                            <h3 className="text-white">Book Appointment</h3>
                            <p className="text-grey"> Dolor sit amet consectetur elit sed do eiusmod tempor incididunt </p>
                        </div>
                    </div>
                    <div className="appointment-inputs row text-grey d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
                        <div className="col-12 col-md-4 mb-3 mb-md-0 gx-md-2">
                            <label htmlFor="name" className="text-white">Your Name</label>
                            <Form.Control
                                type="text"
                                id="name"
                                className="appointment-inputs"
                            />
                        </div>
                        <div className="col-12 col-md-4 mb-3 mb-md-0 gx-md-2">
                            <label htmlFor="name" className="text-white">Your Email</label>
                            <Form.Control
                                type="email"
                                id="email"
                                className="appointment-inputs"
                            />
                        </div>
                        <div className="col-12 col-md-4 gx-md-2">
                            <label htmlFor="services">Services</label>
                            <Form.Select>
                                <option>---</option>
                                <option>UI/UX Design</option>
                                <option>Web Development</option>
                                <option>App Development</option>
                                <option>Software Development</option>
                                <option>Enterprise Solutions</option>
                                <option>Manage IT Services</option>
                            </Form.Select>
                        </div>
                    </div>
                    <div className="row mt-4 d-flex align-items-center justify-content-center justify-content-md-between">
                        <div className="col-12 col-md-9 row mb-5 mb-md-0">
                            <small className="col-12 col-md-11 text-grey small-text">
                                Lorem ipsum dolor amet constur adipisicing elit sed eiusmpor incid dolore magna aliqu enim minim veniam quis nostrud exercittion.
                            </small>
                        </div>
                        <div className="col-9 col-md-3 pe-md-1 d-flex align-items-start justify-content-start">
                            <button className="px-5 py-md-2 py-2 px-md-3 button-font secondaryButton" style={{ width: "100%", fontWeight: 600 }}>Get Appointment</button>
                            {/* <Button variant="warning" style={{ width: "100%", fontWeight: 600 }}>Get Appointment</Button>{' '} */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Appointment;