import React from 'react';
import './App.css';

import {
  HashRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import HomePage from './components/HomePage/HomePage';
import ContactPage from './components/ContactPage/ContactPage';
import AboutWorkPage from './components/AboutWorkPage/AboutWorkPage';
import AboutOfficePage from './components/AboutOfficePage/AboutOfficePage';
import CareerPage from './components/CareerPage/CareerPage';
import SuccessPage from './components/SuccessPage/SuccessPage';
import UiUxPage from './components/UiUxPage/UiUxPage';
import WebDevelopmentPage from './components/WebDevelopmentPage/WebDevelopmentPage';
import AppDevelopmentPage from './components/AppDevelopmentPage/AppDevelopmentPage';
import SoftwareDevelopmentPage from './components/SoftwareDevelopmentPage/SoftwareDevelopmentPage';
import EnterpriseSolutionsPage from './components/EnterpriseSolutionsPage/EnterpriseSolutionsPage';
import ManagedItPage from './components/ManagedItPage/ManagedItPage';

import Notfound404 from './components/Notfound404/Notfound404';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='home' element={<HomePage />} />

        <Route path='about_us' element={<AboutWorkPage />} />
        <Route path='life_at_ZayCo' element={<AboutOfficePage />} />
        <Route path='career' element={<CareerPage />} />

        <Route path='ui_ux_design' element={<UiUxPage />} />
        <Route path='web_development' element={<WebDevelopmentPage />} />
        <Route path='app_development' element={<AppDevelopmentPage />} />
        <Route path='software_development' element={<SoftwareDevelopmentPage />} />
        <Route path='enterprise_solutions' element={<EnterpriseSolutionsPage />} />
        <Route path='managed_it_services' element={<ManagedItPage />} />

        <Route path='contact' element={<ContactPage />} />
        <Route path='success' element={<SuccessPage />} />
        
        <Route path="*" element={<Notfound404 />} />
      </Routes>
    </Router>
  );
}

export default App;
