import React from 'react';
import bestExperts from './../../../assets/images/Experts/best-experts.png';

const BestExperts = () => {
    return (
        <div className="row d-flex justify-content-center align-items-center" style={{ backgroundColor: '#F5F5F5' }}>
            <div className="col-10 col-md-8 d-flex flex-column align-items-center">
                <h2 className="text-center fw-bold my-5 py-md-5 ">Meet with Our Best Experts</h2>
                <img src={bestExperts} alt="" className="img-fluid mx-auto" />
            </div>
        </div>
    );
};

export default BestExperts;