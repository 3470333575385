import React from 'react';
import './FadedImageSlider.scss';

import service0 from './../../../assets/images/services/service0.png';
import elliplse from './../../../assets/images/sliders/Ellipse 4.svg';


const FadedImageSlider = () => {
    return (
        <>
            {/* <div className="container-block"> */}
            {/* <div id="images-slider"> */}

            <div style={{position: 'relative',}}>
                <div className="image-holder">
                    <img src={service0} alt="image0" className="image-content-slide" id="slide-one-image" />
                    <img src={service0} alt="image1" className="image-content-slide" id="slide-two-image" />
                    <img src={service0} alt="image2" className="image-content-slide" id="slide-three-image" />
                </div>
                <div style={{position: 'absolute', bottom: '27px', right: '7%', zIndex: 0}}>
                    <img src={elliplse} alt="" />
                </div>
            </div>
            {/* </div> */}
            {/* </div> */}
        </>
    );
};

export default FadedImageSlider;