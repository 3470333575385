import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";

import NavigationBar from '../Shared/NavigationBar/NavigationBar';
import videoPlayer from '../../assets/images/videoPlayer.png'
import WorkingProcess from '../HomeComponents/WorkingProcess/WorkingProcess';
import Services from '../HomeComponents/Services/Services';
import Provisions from '../HomeComponents/Provisions/Provisions';
import Clients from '../HomeComponents/Clients/Clients';
import Footer from '../Shared/Footer/Footer';

import './AboutWorkPage.css';
import Review from '../Shared/Review/Review';

import designedBottomBanner from '../../assets/images/about-bottom-styled-section.png';

const AboutWorkPage = () => {
    const aboutCompany = [
        {
            id: 1,
            title: `Completed Projects`,
        },
        {
            id: 2,
            title: `Satisfied Clients`,
        },
        {
            id: 3,
            title: `Experienced Staffs`,
        },
    ];

    const [viewPortEntered, setViewPortEntered] = useState(false);
    
    return (
        <>
            <NavigationBar />
            {/* about header */}
            <div className="mt-5 row d-flex justify-content-center align-items-center">
                <div className="row col-11 col-md-6 mb-5 my-md-5">
                    <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
                        <h2 className=" fw-bold">About</h2>
                    </div>
                    <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
                        <h2 className=" fw-bold">Zay Corporation Ltd.</h2>
                    </div>
                </div>
            </div>
            {/* story section */}
            <div className="row d-flex justify-content-center align-items-center mb-md-5">
                <div className="row col-11 col-md-8 mb-md-4">
                    <div className="col-12">
                        <h3 className="fw-bold">Our Story</h3>
                    </div>
                </div>
                <div className="row col-11 col-md-8 mb-md-5">
                    <div className="col-12">
                        <p className="mobile-responsive-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci minima hic facere placeat praesentium ullam eius dolor. Inventore, beatae eius?</p>
                    </div>
                </div>
                <div className="row col-9 col-md-6 d-flex justify-content-center align-items-center">
                    <div className="col-11">
                        <img src={videoPlayer} alt="videoPlayer" className="img-fluid" />
                    </div>
                </div>
            </div>
            {/* working process */}
            <WorkingProcess />
            <Services />
            <Provisions />
            {/* statistics */}
            <div className="row d-flex justify-content-center align-items-center acievements" style={{ backgroundColor: "#2C2C2C", color: '#fff' }}>
                <div className="col-11 col-md-8 row d-flex justify-content-center align-items-center py-3 py-md-5">
                    {/* <div className="row col-4 col-md-4 gy-2 gy-md-3">
                        <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
                            <h2 className="secondaryText">120</h2>
                        </div>
                        <div className="col-12 col-md-12 d-flex justify-content-center align-items-center text-center">
                            <h6>Completed Projects</h6>
                        </div>
                    </div>
                    <div className="row col-4 col-md-4 gy-2 gy-md-3">
                        <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
                            <h2 className="secondaryText">120</h2>
                        </div>
                        <div className="col-12 col-md-12 d-flex justify-content-center align-items-center text-center">
                            <h6>Satisfied Clients</h6>
                        </div>
                    </div> */}
                    {
                        aboutCompany?.map((item, index) =>
                            <div className="row col-4 col-md-4 gy-2 gy-md-3">
                                <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
                                    {/* <h2 className="secondaryText"> */}
                                    <CountUp end={120} durations={5} start={viewPortEntered ? null : 0} >
                                        {({ countUpRef }) => {
                                            return (
                                                <VisibilitySensor
                                                    active={!viewPortEntered}
                                                    onChange={isVisible => {
                                                        if (isVisible) {
                                                            setViewPortEntered(true);
                                                        }
                                                    }}
                                                    delayedCall
                                                >
                                                    <h2 className="secondaryText" ref={countUpRef} >120</h2>
                                                </VisibilitySensor>
                                            );
                                        }}

                                    </CountUp>
                                    {/* </h2> */}
                                </div>
                                <div className="col-12 col-md-12 d-flex justify-content-center align-items-center text-center">
                                    <h6>{item.title}</h6>
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>
            {/* designed contact */}
            <div className="row my-5">
                <div className="col-12 col-md-12">
                    <div className="designed-contact py-md-5">
                        <img src={designedBottomBanner} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
            {/* <div className="row d-flex my-5 designed-contact-parent">
                <div className="col-7 col-md-7 left-contact">
                    <div className="row d-flex justify-content-end align-items-center p-2 p-md-5">
                        <div className="row col-11 col-md-8 p-3 p-md-5 ps-md-0">
                            <div className="col-12 pb-md-5">
                                <h2 className="fw-bold d-none d-md-block">We run all kinds of IT services that vow your success.</h2>
                                <h6 className="fw-bold d-block d-md-none">We run all kinds of IT services that vow your success.</h6>
                            </div>
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <button className="px-2 py-md-2 p-1 px-md-5 fw-bold secondaryButton">Contact Us</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-7 mt-4 mb-5 right-contact">
                    <div className="row d-flex justify-content-end align-items-center p-md-5">
                        <div className="row col-12 p-md-3">
                            <div className="col-11 col-md-9 pb-md-5 pt-md-3 pt-3">
                                <h2 className="fw-bold d-none d-md-block">Make a contact with our IT staffs. Call Us?</h2>
                                <h6 className="fw-bold d-block d-md-none">Make a contact with our IT staffs. Call Us?</h6>
                            </div>
                            <div className="col-12">
                                <h3 className="primaryText fw-bold d-none d-md-block">+8801916-009922</h3>
                                <h6 className="primaryText fw-bold d-md-none d-block">+8801916-009922</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Review />
            <Clients />
            <Footer />
        </>
    );
};

export default AboutWorkPage;