import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "./Review.css";

// import required modules
import { Autoplay, FreeMode, Pagination, Mousewheel, Keyboard } from "swiper";

import customer0 from '../../../assets/images/Customers/customer0.png';
import customer1 from '../../../assets/images/Customers/customer1.png';

const Review = () => {
    const clientsReview = [
        {
            id: 1,
            image: customer0,
            name: `Taki Kauser`,
            comments:
                `Without a strategically designed UX/UI, brands fail to engage customers online. So, UX/UI design is essential for your digital product to succeed.`,
        },
        {
            id: 2,
            image: customer1,
            name: `Tonmoy Kumar Roy`,
            comments:
                `Without a strategically designed UX/UI, brands fail to engage customers online. So, UX/UI design is essential for your digital product to succeed.`,
        },
        {
            id: 3,
            image: customer0,
            name: `Bishal Das`,
            comments:
                `Without a strategically designed UX/UI, brands fail to engage customers online. So, UX/UI design is essential for your digital product to succeed.`,
        },
        {
            id: 4,
            image: customer1,
            name: `Asaduzzaman Nur`,
            comments:
                `Without a strategically designed UX/UI, brands fail to engage customers online. So, UX/UI design is essential for your digital product to succeed.`,
        },
        {
            id: 5,
            image: customer0,
            name: `Moshiur Rahman`,
            comments:
                `Without a strategically designed UX/UI, brands fail to engage customers online. So, UX/UI design is essential for your digital product to succeed.`,
        },
        {
            id: 6,
            image: customer1,
            name: `Jeffy mahin`,
            comments:
                `Without a strategically designed UX/UI, brands fail to engage customers online. So, UX/UI design is essential for your digital product to succeed.`,
        },
    ];

    return (
        <>
            <div className="row d-flex justify-content-center align-items-center review-area">
                <div className="col-11 col-md-8">
                    <div className="row mt-5 mt-md-5">
                        <div className="col-12 col-md-12">
                            <h2 className="fw-bold text-center">See What Our Customers Are Saying</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <Swiper
                                // slidesPerView={2}
                                spaceBetween={50}
                                freeMode={true}
                                pagination={{
                                    clickable: true,
                                }}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Autoplay, FreeMode, Pagination, Mousewheel, Keyboard]}
                                className="mySwiper"
                                loop={true}
                                autoplay={{
                                    delay: 2000,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={
                                    {
                                        // when window width is >= 320px
                                        320: {
                                            slidesPerView: 1,
                                        },
                                        // when window width is >= 767px
                                        767: {
                                            slidesPerView: 2,
                                        }
                                    }
                                }
                            >
                                {
                                    clientsReview.slice(0,3).map((review, index) =>
                                        <SwiperSlide  key={review.id}>
                                            <div className="row py-5 pb-md-5" style={{ backgroundColor: '#F5F5F5' }}>
                                                <div className="col-12 col-md-12">
                                                    <div className="row d-flex justify-content-start align-items-center pb-3 pb-md-3 ps-2 ps-md-2">
                                                        <div className="col-2 col-md-2">
                                                            <img src={review.image} alt="client" height="60" />
                                                        </div>
                                                        <div className="col-10 col-md-10 ps-3 pt-3 ps-md-0 pt-md-3">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <h6 className="fw-bold text-start">{review.name}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <p className="text-start fs-6">Founder at X Ltd.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row review p-3 mb-3 mx-2 bg-body rounded">
                                                        <div className="col-12 col-md-12">
                                                            <p className="text-start fs-6">{review.comments}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Review;