import React from 'react';
import BottomBanner from '../HomeComponents/BottomBanner/BottomBanner';
import Clients from '../HomeComponents/Clients/Clients';
import Footer from '../Shared/Footer/Footer';
import NavigationBar from '../Shared/NavigationBar/NavigationBar';
import ServicesTypes from '../Shared/ServicesTypes/ServicesTypes';

import softwareDevelopment0 from '../../assets/images/SoftwareDevelopment/softwareDevelopment0.png';

const SoftwareDevelopmentPage = () => {
    return (
        <>
            <NavigationBar />
            <section>
                <div className="row d-flex justify-content-center align-items-center mt-md-5">
                    <div className="col-11 col-md-6">
                        <div className="row mt-md-5 mb-md-3">
                            <div className="col-12 col-md-12">
                                <h2 className="fw-bold text-center">Customized Software Development Services</h2>
                            </div>
                        </div>
                        <div className="row my-md-3">
                            <div className="col-12 col-md-12">
                                <p className="mobile-responsive-text text-center">Pioneer in the field of the ICT industry, SSL Wireless has been developing cutting-edge applications, software, and solutions for a wide range of industries for the last 12 years.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ServicesTypes />
            <section>
                <div className="row mt-5 d-flex justify-content-center align-items-center" style={{ backgroundColor: '#F5F5F5' }}>
                    <div className="col-11 col-md-8 my-md-3 py-5">
                        <div className="row d-flex justify-content-center align-items-center mb-4 mb-md-5">
                            <div className="col-12 col-md-10">
                                <h3 className="fw-bold text-start text-md-center">Scalable, Flexible And Cost-Effective Software Development For All Types Of Businesses</h3>
                            </div>
                        </div>
                        <div className="row d-flex flex-md-row justify-content-between align-items-center">
                            <div className="col-12 col-md-5 text-secondary mb-4 mb-md-0">
                                <p className="mobile-responsive-text">We design, create, deploy and maintain specially built solutions for organizations with narrowly defined requirements. Contrary to the traditional off-the-shelf software, our customized solutions are essential for distinctive organizations with unique or special business needs. Southtech offers you the most secure and best-customized software development services in the country.</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <img src={softwareDevelopment0} alt="officeEmployee" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5 mt-md-0 d-flex justify-content-center align-items-center">
                    <div className="col-11 col-md-8 my-md-5 my-3">
                        <div className="row mb-5 mb-md-5">
                            <div className="col-12 col-md-12">
                                <h2 className="fw-bold text-center">We Don't Compromise On Quality</h2>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-between align-items-center py-md-3">
                            <div className="col-12 col-md-6 mb-5 mb-md-0">
                                <img src={softwareDevelopment0} alt="officeEmployee" className="img-fluid" />
                            </div>
                            <div className="col-12 col-md-5 mb-4 mb-md-0">
                                <h6 className="fw-bold">With You, All The Way!</h6>
                                <p className="text-start mobile-responsive-text">We also provide an after-sales service for easy maintenance of software whenever necessary. Get our expert support and maintenance services to smoothly navigate through and to overcome your IT challenges. Apart from customized software development, we ensure integrated and consistent support that you need to achieve greater productivity and effectiveness in your business. Starting from brainstorming, our support team is committed to helping you throughout the entire project and its post-implementation care.</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-between align-items-center py-md-3">
                            <div className="col-12 col-md-5 mb-4 mb-md-0">
                                <h6 className="fw-bold">Because Communication Is Key</h6>
                                <p className="text-start mobile-responsive-text">We strictly follow our web development and service delivery standards. For smooth and transparent communication with our clients, we employ our online CRM software and record each and every step that is initiated to resolve any issues or to provide any support.</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <img src={softwareDevelopment0} alt="officeEmployee" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <BottomBanner />
            <Clients />
            <Footer />
        </>
    );
};

export default SoftwareDevelopmentPage;