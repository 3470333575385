import React from 'react';
// import { Button } from 'react-bootstrap';
// import FadedImageSlider from '../../Shared/FadedImageSlider/FadedImageSlider';
import bannerImage from './../../../assets/images/banner-image.svg';
// import image0 from './../../../assets/images/sliders/banner-image-1.svg';
// import image1 from './../../../assets/images/sliders/banner-image-2.svg';
// import image2 from './../../../assets/images/sliders/banner-image-3.svg';
// import image1 from './assets/images/sliders/banner-image-2.svg';
// import image2 from './assets/images/sliders/banner-image-3.svg';
// import image0 from '/assets/images/sliders/app-dev-1.svg';
// import image1 from '/assets/images/sliders/app-dev-2.svg';
// import image2 from '/assets/images/sliders/app-dev-3.svg';

// import image from '/public/assets/images/sliders/'

import './BannerCarousel.css';

const BannerCarousel = () => {
    // const images = { image0, image1, image2 };
    return (
        <>
            <div className="row d-flex justify-content-center align-items-center py-3 py-md-0 mt-md-5" style={{ backgroundColor: '#F5F5F5' }}>
                <div className="ps-md-0 ms-md-0 col-md-8 col-10 col-sm-11 row d-sm-flex justify-content-sm-center">
                    {/* <div className="row"> */}
                    <div className="row col-12 col-sm-8 col-md-6 ms-md-0 ps-md-0 d-flex flex-column justify-content-center justify-content-md-around align-items-md-start text-center">
                        {/* <div className="d-flex flex-column justify-content-center align-items-start"> */}
                        {/* <div className="row d-flex flex-column justify-content-start align-items-start"> */}
                        <div className="col-12 col-md-12 image-holder slider text-holder m-0 d-flex justify-content-center justify-content-md-start align-items-center" style={{right: 0}}>
                            <h1 className="image-content-slide slide1 text-center text-md-start text-content-slide">Grow Your Business With Us</h1>
                            <h1 className="image-content-slide slide2 text-center text-md-start text-content-slide">Best Solution for your Business</h1>
                            <h1 className="image-content-slide slide3 text-center text-md-start text-content-slide">Mordernize Your Business With Us</h1>
                        </div>
                        <div className="row col-12 col-md-11 mt-5 mt-md-0 my-0 py-0 ps-0 ps-md-0 my-md-0 text-md-start d-md-flex justify-content-start align-items-center">
                            <div className="col-md-6 button-top-margin">
                                <button className="px-5 py-md-2 py-2 px-md-3 button-font secondaryButton" style={{fontWeight: 600}}>Explore Our Website</button>
                            </div>
                            <div className="col-md-6 button-top-margin">
                                <button className='px-5 my-3 my-md-0 py-md-2 py-2 px-md-5 button-font primaryButton' style={{fontWeight: 600}}>Contact Us</button>
                            </div>
                        </div>
                        {/* </div> */}
                        {/* </div> */}
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 ms-md-0 ps-md-0 d-none d-md-block">
                        {/* <div className="row"> */}
                            <div className="row col-12 col-md-12 d-flex justify-content-center align-self-end">
                                <img
                                    src={bannerImage}
                                    alt="First slide"
                                    // height="100%"
                                    // width="100%"
                                    className="img-fluid"
                                />
                            {/* </div> */}
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>

            {/* <div className="row mx-auto d-sm-flex justify-content-center align-items-center border-box" style={{ backgroundColor: '#F5F5F5' }}>
                <div className="col-md-4" style={{ marginLeft: 0, paddingLeft: 0 }}>
                    <div className="d-flex flex-column justify-column-center ">
                        <div>
                            <div className="image-holder slider" style={{height: '150px'}}>
                                <h1 className="image-content-slide slide1" style={{ transform: 'translate(0, 0)' }}>Grow Your Business With Us</h1>
                                <h1 className="image-content-slide slide2" style={{ transform: 'translate(0, 0)' }}>Best Solution for your Business</h1>
                                <h1 className="image-content-slide slide3" style={{ transform: 'translate(0, 0)' }}>Mordernize Your Business With Us</h1>
                            </div>

                            <div className="my-3 text-sm-center text-md-start">
                                <Button variant="warning" className="py-2" style={{ width: '200px', fontWeight: '600' }}>Explore Our Website</Button>{' '}
                                <Button variant="warning" className='ms-2 py-2' style={{ width: '200px', fontWeight: '600' }}>Contact Us</Button>{' '}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    {/* <FadedImageSlider images={images} /> */}

            {/* <img
                        // className="d-block"
                        src={bannerImage}
                        alt="First slide"
                        height="100%"
                        width="100%"
                    // height="531"
                    // width="626"
                    />
                </div> */}
            {/* </div>  */}
        </>
    );
};

export default BannerCarousel;