import React from 'react';
import { Link } from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import BottomBanner from '../HomeComponents/BottomBanner/BottomBanner';
import Clients from '../HomeComponents/Clients/Clients';
import Footer from '../Shared/Footer/Footer';
import NavigationBar from '../Shared/NavigationBar/NavigationBar';

import enterpriseSoutions0 from '../../assets/images/EnterpriseSolutions/enterpriseSolutions0.png';
import enterpriseSoutions1 from '../../assets/images/EnterpriseSolutions/enterpriseSolutions1.png';
import enterpriseSoutions2 from '../../assets/images/EnterpriseSolutions/enterpriseSolutions2.png';
import enterpriseSoutions3 from '../../assets/images/EnterpriseSolutions/enterpriseSolutions3.png';
import enterpriseSoutions4 from '../../assets/images/EnterpriseSolutions/enterpriseSolutions4.png';
import enterpriseSoutions5 from '../../assets/images/EnterpriseSolutions/enterpriseSolutions5.png';
import Review from '../Shared/Review/Review';

const EnterpriseSolutionsPage = () => {
    const enterpriseSoutionsData = [
        {
            id: 1,
            image: enterpriseSoutions0,
            title: `ERP Software`,
            description: `Our expert team of mobile application developers is ready to develop apps for Android & iOS platforms to meet our clients’ business requirements.`,
        },
        {
            id:2,
            image: enterpriseSoutions1,
            title: `POS Software`,
            description: `Chronologically patronizing our end-to-end eCommerce caliber, we affirm holistic solutions gratifying the ever-changing business demands of our customers.`,
        },
        {
            id: 3,
            image: enterpriseSoutions2,
            title: `VAT Software`,
            description: `Being Bangladesh’s first converged payment solution conglomerate, our tailormade fintech solutions aid local and global merchants in emerging markets with various payment methods.`,
        },
        {
            id: 4,
            image: enterpriseSoutions3,
            title: `E-wallet`,
            description: `A leading provider of custom marketplaces across the globe, our top-of the-notch technology and enablement capabilities empower tradesmen to perform their business with ease.`,
        },
        {
            id: 5,
            image: enterpriseSoutions4,
            title: `Chatbot`,
            description: `We understand that a website is the genome of any business today and we validate it with our sterling 360-degree web solutions that aim to disrupt the market status quo.`,
        },
        {
            id: 6,
            image: enterpriseSoutions5,
            title: `Payroll Management System (PMS)`,
            description: `We have a wide range of experience in developing various web-based applications to enable businesses to automate and generate reports backed by the smart articulation of measurable data.`,
        },
    ];
    return (
        <>
            <NavigationBar />

            {/* content section */}
            <section>
                <div className="row d-flex justify-content-center align-items-center mt-md-5">
                    <div className="col-11 col-md-6">
                        <div className="row mt-md-5 mb-md-3">
                            <div className="col-12 col-md-12">
                                <h2 className="fw-bold text-center">Enterprise Solutions</h2>
                            </div>
                        </div>
                        <div className="row my-md-3 my-4">
                            <div className="col-12 col-md-12">
                                <p className="mobile-responsive-text text-center">Pioneer in the field of the ICT industry, SSL Wireless has been developing cutting-edge applications, software, and solutions for a wide range of industries for the last 12 years.</p>
                            </div>
                        </div>
                        {
                            enterpriseSoutionsData.map((item, index) =>
                                <div key={item.id} className="row d-flex justify-content-center align-items-center my-5 my-md-5">
                                    <div className="col-10 col-md-9">
                                        <div className="row">
                                            <div className="col-12 col-md-12 gx-0 shadow" style={{ border: 'none', borderRadius: '5px' }}>
                                                <div className="row">
                                                    <div className="col-12 col-md-12">
                                                        <img className="img-fluid" width="100%" src={item?.image} alt="app-development" />
                                                    </div>
                                                </div>
                                                <div className="row mt-4 mb-2 px-3">
                                                    <div className="col-12 col-md-12">
                                                        <h5 style={{ fontWeight: 600 }}>{item?.title}</h5>
                                                    </div>
                                                </div>
                                                <div className="row px-3">
                                                    <div className="col-12 col-md-12">
                                                        <p className="fs-6">{item?.description}</p>
                                                    </div>
                                                </div>
                                                <div className="row mb-4 px-3">
                                                    <div className="col-12 col-md-12 d-flex justify-content-end">
                                                        <Link to="/" className="primaryText text-decoration-none" style={{ fontWeight: 600 }}>Get Appointment <BsArrowRight /> </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>

            <Review />
            <BottomBanner />
            <Clients />
            <Footer />
        </>
    );
};

export default EnterpriseSolutionsPage;