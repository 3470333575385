import React from 'react';
import Footer from '../Shared/Footer/Footer';
import NavigationBar from '../Shared/NavigationBar/NavigationBar';

import officeEmployeeMobile from '../../assets/images/officeEmployeeMobile.png';
import officeEmployee from '../../assets/images/officeEmployee.png';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { Autoplay, FreeMode, Pagination, Mousewheel, Keyboard } from "swiper";

import './AboutOfficePage.css';

const AboutOfficePage = () => {

    const employeeAlbum = [
        {
            id: 1,
            image: officeEmployee,
            mobileImage: officeEmployeeMobile,
        },
        {
            id: 2,
            image: officeEmployee,
            mobileImage: officeEmployeeMobile,
        },
        {
            id: 3,
            image: officeEmployee,
            mobileImage: officeEmployeeMobile,
        },
        {
            id: 4,
            image: officeEmployee,
            mobileImage: officeEmployeeMobile,
        },
        {
            id: 5,
            image: officeEmployee,
            mobileImage: officeEmployeeMobile,
        },
        {
            id: 6,
            image: officeEmployee,
            mobileImage: officeEmployeeMobile,
        },
    ];

    return (
        <>
            <NavigationBar />
            <div className="row my-md-5 d-flex justify-content-center align-items-center">
                <div className="col-11 col-md-8 mt-md-5 mt-0">
                    <div className="row">
                        <div className="col-12 col-md-6 mb-5 mt-md-3">
                            <h3 className="fw-bold text-center text-md-start">Life At Zay Corporation</h3>
                        </div>
                    </div>
                    <div className="row d-flex flex-column-reverse flex-md-row justify-content-between align-items-center">
                        <div className="col-12 col-md-5">
                            <p className="mobile-responsive-office-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Necessitatibus odio illum repellendus dignissimos officiis voluptates officia sint in voluptatem.</p>
                        </div>
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <img src={officeEmployee} alt="officeEmployee" className="d-none d-md-block img-fluid" />
                            <img src={officeEmployeeMobile} alt="officeEmployeeMobile" className="d-block d-md-none w-100" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row my-md-5 d-flex justify-content-center align-items-center">
                <div className="col-11 col-md-8 mt-md-5 mt-5 mb-5 mb-md-0">
                    <div className="row mb-5 mb-md-5">
                        <div className="col-12 col-md-12 mb-3 mb-md-3">
                            <h3 className="fw-bold text-end d-none d-md-block">Our Journey Over The Years</h3>
                            <h5 className="fw-bold text-end d-block d-md-none">Our Journey Over The Years</h5>
                        </div>
                        <div className="col-12 col-md-12">
                            <p className="text-end mobile-responsive-office-text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eaque quos veritatis commodi, odit praesentium excepturi dolores quasi incidunt ea molestias natus nobis temporibus sed beatae. Enim quos minima fuga aliquam!</p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-between align-items-center mb-md-5">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <img src={officeEmployee} alt="officeEmployee" className="d-none d-md-block img-fluid" />
                            <img src={officeEmployeeMobile} alt="officeEmployeeMobile" className="d-block d-md-none w-100" />
                        </div>
                        <div className="col-12 col-md-5 mb-5">
                            <p className="text-end mobile-responsive-office-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Necessitatibus odio illum repellendus dignissimos officiis voluptates officia sint in voluptatem.</p>
                        </div>
                    </div>
                    <div className="row d-flex flex-column-reverse flex-md-row justify-content-between align-items-center mb-md-5">
                        <div className="col-12 col-md-5">
                            <p className="text-start mobile-responsive-office-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Necessitatibus odio illum repellendus dignissimos officiis voluptates officia sint in voluptatem.</p>
                        </div>
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <img src={officeEmployee} alt="officeEmployee" className="d-none d-md-block img-fluid" />
                            <img src={officeEmployeeMobile} alt="officeEmployeeMobile" className="d-block d-md-none w-100" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row my-md-5 pt-md-5 d-flex justify-content-center align-items-center office-album" style={{ backgroundColor: '#F5F5F5' }}>
                <div className="col-11 col-md-8 my-5">
                    <div className="row">
                        <div className="col-12 col-md-12 mb-4 mb-md-4">
                            <h3 className="fw-bold text-center d-none d-md-block">People of Zay Corporation</h3>
                            <h5 className="fw-bold text-center d-block d-md-none">People of Zay Corporation</h5>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-12 col-md-12 text-secondary">
                            <p className="mobile-responsive-office-text text-start text-md-center">Over the years, we have built and nurtured brilliant teams that have contributed directly to pioneering digital transformation in the Bangladeshi corporate ecosystem. Here at Southtech, we respect and value the diverse beliefs, characteristics, and perspectives that shape each individual. We celebrate our diversity and firmly believe that this is a key ingredient to a healthy and prosperous working environment. We believe that a great organization is great only because of its people.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <Swiper
                                // slidesPerView={2}
                                spaceBetween={30}
                                freeMode={true}
                                pagination={{
                                    clickable: true,
                                }}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Autoplay, FreeMode, Pagination, Mousewheel, Keyboard]}
                                className="mySwiper"
                                loop={true}
                                autoplay={{
                                    delay: 2000,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={
                                    {
                                        // when window width is >= 320px
                                        320: {
                                            slidesPerView: 1,
                                        },
                                        // when window width is >= 767px
                                        767: {
                                            slidesPerView: 2,
                                        }
                                    }
                                }
                            >
                                {
                                    employeeAlbum.slice(0, 3).map((item, index) =>
                                        <SwiperSlide key={item.id}>
                                            <div className="row pt-4 pb-5 pt-md-5 pb-md-5" style={{ backgroundColor: '#F5F5F5' }}>
                                                <div className="col-12 col-md-12">
                                                    <div className="row d-flex justify-content-center align-items-center pb-3 pb-md-3 ps-2 ps-md-2">
                                                        <div className="col-12 col-md-12">
                                                            <img src={item.image} alt="album-item" height="" className="d-none d-md-block img-fluid" />
                                                            <img src={item.mobileImage} alt="album-item" height="" className="d-block d-md-none img-fluid" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row my-md-5 d-flex justify-content-center align-items-center">

                <div className="col-11 col-md-8 mb-md-5 mt-5 mb-5">
                    <div className="row">
                        <div className="col-12 col-md-12 mb-5 mb-md-5">
                            <h3 className="fw-bold text-center d-none d-md-block">Always Finding a Way To Succeed</h3>
                            <h5 className="fw-bold text-center d-block d-md-none">Always Finding a Way To Succeed</h5>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-between align-items-center">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <img src={officeEmployee} alt="officeEmployee" className="d-none d-md-block img-fluid" />
                            <img src={officeEmployeeMobile} alt="officeEmployeeMobile" className="d-block d-md-none w-100" />
                        </div>
                        <div className="col-12 col-md-5">
                            <p className="text-end mobile-responsive-office-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Necessitatibus odio illum repellendus dignissimos officiis voluptates officia sint in voluptatem.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AboutOfficePage;