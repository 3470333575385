import React from 'react';
import Notfound404 from '../Notfound404/Notfound404';

const SuccessPage = () => {
    return (
        <>
            <Notfound404 />
        </>
    );
};

export default SuccessPage;