import React from 'react';
import { BsArrowRight } from "react-icons/bs";
import BottomBanner from '../HomeComponents/BottomBanner/BottomBanner';
import Clients from '../HomeComponents/Clients/Clients';
import Footer from '../Shared/Footer/Footer';
import NavigationBar from '../Shared/NavigationBar/NavigationBar';
import Review from '../Shared/Review/Review';

import './WebDevelopmentPage.css';

import solution0 from '../../assets/images/WebDepelopment/solutions/solution0.png';
import solution1 from '../../assets/images/WebDepelopment/solutions/solution1.png';
import solution2 from '../../assets/images/WebDepelopment/solutions/solution2.png';
import solution3 from '../../assets/images/WebDepelopment/solutions/solution3.png';

import scope0 from '../../assets/images/WebDepelopment/Scopes/scope0.png';
import scope1 from '../../assets/images/WebDepelopment/Scopes/scope1.png';
import scope2 from '../../assets/images/WebDepelopment/Scopes/scope2.png';
import scope3 from '../../assets/images/WebDepelopment/Scopes/scope3.png';
import scope4 from '../../assets/images/WebDepelopment/Scopes/scope4.png';
import scope5 from '../../assets/images/WebDepelopment/Scopes/scope5.png';

import serviceOption0 from '../../assets/images/WebDepelopment/serviceOption/service-option0.png';
import serviceOption1 from '../../assets/images/WebDepelopment/serviceOption/service-option1.png';
import { Link } from 'react-router-dom';

const WebDevelopmentPage = () => {

    const solutions = [
        {
            id: 1,
            image: solution0,
            title: `Websites`,
            description: `250+ businesses, governmental and non-profit organizations
            use the websites we’ve created for corporate presentation
            and brand building. We make sure our websites for dynamic content management.`,
        },
        {
            id: 2,
            image: solution1,
            title: `Web portals`,
            description: `Since 2005, ScienceSoft has created web portals for different
            audiences: customers, business partners, ecommerce users,
            patients, vendors, interest-based communities.`,
        },
        {
            id: 3,
            image: solution2,
            title: `Ecommerce`,
            description: `We create ecommerce solutions for B2C, B2B and
            marketplace business models. We can smoothly integrate
            an ecommerce component into your operating web app or
            launch a new ecommerce.`,
        },
        {
            id: 4,
            image: solution3,
            title: `Enterprise (corporate) web solutions`,
            description: `We build web apps for efficient management of different
            business activities. We apply smart automation to streamline
            orkflows and integrate corporate systems together for
            coherent operation.`,
        },
    ];

    const scopes = [
        {
            id: 1,
            image: scope0,
            title: `Business analysis`,
            description: `Our business analysts focus on the needs of your target
            audience to perform requirements engineering and outline
            the scope of the solution. They also bridge the gap between
            business stakeholders and an IT team to keep all the
            involved parties aligned.`,
        },
        {
            id: 2,
            image: scope1,
            title: `Front-end design and development`,
            description: `Our web designers closely analyze the user audience to
            understand their needs and reflect these findings in UI
            design. Having agreed on the look and feel of a web app
            with project stakeholders, we bring it to life with front-end
            coding.`,
        },
        {
            id: 3,
            image: scope2,
            title: `Back-end development`,
            description: `Our developers accurately implement the business logic
            of your web app on the back end. We rely on proven
            frameworks to ensure fast and quality coding.`,
        },
        {
            id: 4,
            image: scope3,
            title: `Integration`,
            description: `We set up APIs to integrate your web app with corporate or
            third-party systems and services. App integration ensures
            immediate data synchronization across systems.`,
        },
        {
            id: 5,
            image: scope4,
            title: `Help desk`,
            description: `Providing L1, L2 and L3 support services, we deliver hot-fixes
            within 24 hours to prevent or resolve any issues in usage,
            technology and code that may arise. We help you provide
            high availability and trouble-free functionality of your web
            app.`,
        },
        {
            id: 6,
            image: scope5,
            title: `Continuous support and evolution`,
            description: `We advance your web-based software to keep it efficient,
            competitive on the market, and compliant with all your
            evolving business needs. Our DevOps
            processes let us roll out urgent updates within 1-2 days and
            release new, planned functional modules every 2-6 weeks.`,
        },
    ];

    const serviceOptions = [
        {
            id: 1,
            image: serviceOption0,
            title: `Web development / launch of a web app`,
            description: `We take a proactive approach to web development and elaborate on ways to uncover less obvious business requirements, save costs and envisage risks for your project.`,
            subTitle: `Why choose ZayCo:`,
            points: [
                {
                    id: 1,
                    text: `Skilled UX researchers.`,
                },
                {
                    id: 2,
                    text: `Hands-on consulting expertise in 30+ industries including healthcare, finance, manufacturing, retail.`,
                },
                {
                    id: 3,
                    text: `Experience with all the major app architecture types: microservices-based, traditional 3-layer, cloud native, event-driven, serverless, headless, service-oriented architecture`,
                },
                {
                    id: 4,
                    text: `Full-stack development of web apps.`,
                },
            ],
        },
        {
            id: 2,
            image: serviceOption1,
            title: `Web redesign / modernization`,
            description: `We improve visual appeal, functional fullness and usability of existing web apps. And our clients see increased web conversions, reduced maintenance costs, better ROI from optimized business operations.`,
            subTitle: `Redesign and modernization possibilities we bring to life:`,
            points: [
                {
                    id: 1,
                    text: `Revamp of a legacy technology stack.`,
                },
                {
                    id: 2,
                    text: `Web app re-architecting to enable greater scalability.`,
                },
                {
                    id: 3,
                    text: `Delivery of UX and UI innovations for better user appeal and
                    usability.`,
                },
                {
                    id: 4,
                    text: `Delivery of new web app features.`,
                },
                {
                    id: 5,
                    text: `Incorporation of disparate business modules into a single web
                    application.`,
                },
            ],
        },
    ];

    return (
        <>
            <NavigationBar />

            {/* slutions section */}

            <section>
                <div className="row py-md-5 mb-5 mb-md-0">
                    <div className="col-12 col-md-12">
                        <div className="row d-flex justify-content-center align-items-center mb-5 my-md-5">
                            <div className="col-11 col-md-6">
                                <h2 className="fw-bold text-center">Web Solutions ZayCo Creates</h2>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-11 col-md-9">
                                <div className="row d-flex justify-content-center align-items-center">
                                    {
                                        solutions.map((solution, index) =>
                                            <div key={solution.id} className="col-12 col-md-5 my-3 m-md-3 rounded border" style={{ backgroundColor: '#F5F5F5' }}>
                                                <div className="row d-flex justify-content-center align-items-center">
                                                    <div className="col-12 col-md-12 py-5 py-md-4">
                                                        <div className="row d-flex justify-content-center align-items-center">
                                                            <div className="col-4 col-md-4 d-flex justify-content-center align-items-center">
                                                                <img height="60" width="60" src={solution.image} alt="scope" />
                                                            </div>
                                                        </div>
                                                        <div className="row py-3 py-md-3 mt-3">
                                                            <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
                                                                <h5 className="fw-bold text-center px-4">{solution.title}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row px-4">
                                                            <div className="col-12 col-md-12">
                                                                <p className="fs-6 text-center">{solution.description.slice(0, 180)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 px-3 mt-md-3 px-md-4">
                                                            <div className="col-12 col-md-12 d-flex justify-content-end">
                                                                <Link to="/" className="primaryText text-decoration-none" style={{ fontWeight: 600 }}>Get Appointment <BsArrowRight /> </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* scopes section */}

            <section>
                <div className="row py-md-5 pb-5 mb-5 mb-md-0" style={{ backgroundColor: '#F5F5F5' }}>
                    <div className="col-12 col-md-12">
                        <div className="row d-flex justify-content-center align-items-center my-5 my-md-5">
                            <div className="col-11 col-md-6">
                                <h2 className="fw-bold text-center">The Scope of a Common Web Development Project by ZayCo</h2>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center align-items-center pb-md-5">
                            <div className="col-11 col-md-9">
                                <div className="row d-flex justify-content-center align-items-center">
                                    {
                                        scopes.map((scope, index) =>
                                            <div key={scope.id} className="col-12 col-md-5 my-3 m-md-3 rounded shadow" style={{ backgroundColor: '#fff' }}>
                                                <div className="row d-flex justify-content-center align-items-center">
                                                    <div className="col-12 col-md-12 py-3 py-md-4">
                                                        <div className="row d-flex justify-content-center align-items-center">
                                                            <div className="col-4 col-md-4 d-flex justify-content-center align-items-center">
                                                                <img height="60" width="60" src={scope.image} alt="scope" />
                                                            </div>
                                                        </div>
                                                        <div className="row px-4 py-3 py-md-3 mt-3">
                                                            <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
                                                                <h5 className="fw-bold text-center">{scope.title}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row px-4">
                                                            <div className="col-12 col-md-12">
                                                                <p className="fs-6 text-center">{scope.description.slice(0, 160)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* service options section */}

            <section>
                <div className="row d-flex justify-content-center align-items-center pb-3 pb-md-0 py-md-5 my-md-5">
                    <div className="col-11 col-md-8">
                        <h2 className="fw-bold text-center">Our Service Options</h2>
                    </div>
                </div>
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-11 col-md-8">
                        <div className="row d-flex justify-content-around align-items-center pb-5 pb-md-5">
                            {
                                serviceOptions.map((serviceOption, index) =>
                                    <div key={serviceOption.id} className="col-12 col-md-6 service-option">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className="col-11 col-md-11 border rounded px-4 py-5 px-md-4 py-md-5 my-4" style={{ backgroundColor: '#F5F5F5' }}>
                                                <div className="row d-flex justify-content-center align-items-center">
                                                    <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                                                        <img src={serviceOption.image} alt="serviceOption" />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
                                                        <h4 className="text-center fw-bold py-2 py-md-3">{serviceOption.title}</h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
                                                        <p className="fs-6">{serviceOption.description}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-12">
                                                        <h6 className="fw-bold py-2 py-md-3">{serviceOption.subTitle}</h6>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-12">
                                                        <ul>
                                                            {
                                                                serviceOption?.points.map((point, index) =>
                                                                    <li key={point.id} className="py-1">{point.text}</li>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row mt-5">
                                                    <div className="col-12 col-md-12">
                                                        <Link to="/">
                                                            <button className="primaryButton w-100 py-2 py-md-2">Get Appointment</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>

            <Review />
            <BottomBanner />
            <Clients />
            <Footer />
        </>
    );
};

export default WebDevelopmentPage;