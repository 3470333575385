import React, { useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import './WorkingProcess.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { Autoplay, FreeMode, Pagination, Mousewheel, Keyboard } from "swiper";

const WorkingProcess = () => {

    const [toggleState, setToggleState] = useState(
        {
            id: 1,
            title: `Discovery`,
            description: `Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.`,
        }
    );

    const workProcess = [
        {
            id: 1,
            title: `Discovery`,
            description: `Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.`,
        },
        {
            id: 2,
            title: `Planning`,
            description: `Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.`,
        },
        {
            id: 3,
            title: `Execute`,
            description: `Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.`,
        },
        {
            id: 4,
            title: `Delivery`,
            description: `Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.`,
        },
    ];

    return (
        <>
            <div className="my-5">
                <div className="row mb-0 mb-md-5">
                    <div className="col-md-8 col-11 mx-auto">
                        <h2 className="fw-bold text-center">Our Working Process</h2>
                        <p className="mobile-responsive-text text-start mt-4 mb-0">
                            Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ulac laboris aliquip ex ea commodo consequat duis aute irure.dolor in repre henderit in voluptate velit esse cillum dolore.Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis.
                        </p>
                    </div>
                </div>
                <div className="row d-flex justify-content-center align-items-center pb-md-4 working-process">
                    <div className="col-md-8 col-11 text-start d-none d-md-block">
                        <Row xs={1} sm={2} md={4} className="g-2 g-md-2">
                            {
                                workProcess.map((process, index) =>
                                    <Col key={process.id} onClick={() => setToggleState(process)} className="process-item">
                                        <Card className={toggleState.id === process.id ? "selected-item border-0 px-0 py-0 my-3 my-md-0 me-md-2" : "regular-item border-0 px-0 py-0 my-3 my-md-0 me-md-2"}>
                                            <Card.Body>
                                                <Card.Text className="process-card-font-size">
                                                    {process.description}
                                                </Card.Text>
                                                <Card.Title className="fw-bold">{process.title}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            }
                        </Row>
                    </div>
                    <div className="col-md-8 col-11 d-block d-md-none">
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={50}
                            freeMode={true}
                            pagination={{
                                clickable: true,
                            }}
                            mousewheel={true}
                            keyboard={true}
                            modules={[Autoplay, FreeMode, Pagination, Mousewheel, Keyboard]}
                            className="mySwiper"
                            // loop={true}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                        >
                            {
                                workProcess.map((process, index) =>
                                    <SwiperSlide key={process.id} style={{ backgroundColor: 'transparent' }}>
                                        <div className="row py-5">
                                            <div className="col-12 process-item">
                                                <Card className="selected-item px-0 py-2 my-3 my-md-0 me-md-2">
                                                    <Card.Body>
                                                        <Card.Text className="text-start process-card-font-size">
                                                            {process.description}
                                                        </Card.Text>
                                                        <Card.Title className="text-start fw-bold pt-2 process-card-title">{process.title}</Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            }
                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WorkingProcess;