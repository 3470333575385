import React, { useState } from 'react';
// import { Button } from 'react-bootstrap';

import './Provisions.css';

import provisionImage0 from './../../../assets/images/provision0.png';
import provisionImage1 from './../../../assets/images/provision1.png';
import provisionImage2 from './../../../assets/images/provision2.png';
import provisionImage3 from './../../../assets/images/provision3.png';

// import provisions from './../../../assets/images/provisions.svg';


const Provisions = () => {

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <>
            <div className="my-md-5 py-5 row gx-0 mx-auto d-flex justify-content-center align-items-center provision-texts">
                <div className="col-11 col-md-8">
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <h2 className="fw-bold">Best IT-Solutions We Provide</h2>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center gx-0">
                        <div className="col-md-6 col-12 text-start pe-md-4">
                            <p className="my-4 mobile-responsive-text">Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ulac</p>
                            <div className="provision">
                                <p className="fw-bold d-flex flex-column flex-md-row">
                                    <span onClick={() => toggleTab(1)} className={toggleState === 1 ? "active-item me-md-4 my-1" : "inactive-item me-md-4 my-1"}> Our Vision </span>
                                    <span onClick={() => toggleTab(2)} className={toggleState === 2 ? "active-item mx-md-4 my-1" : "inactive-item mx-md-4 my-1"}> Our Mission </span>
                                    <span onClick={() => toggleTab(3)} className={toggleState === 3 ? "active-item ms-md-4 my-1" : "inactive-item ms-md-4 my-1"}> Our Strength </span>
                                </p>

                                {
                                    toggleState === 1 &&
                                    <div>
                                        <p className="my-4 mobile-responsive-text">
                                            Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et
                                            dolore magna aliqua enim ad minim veniam quis nostrud exercitation ulac
                                            laboris aliquip ex ea commodo consequat duis aute irure.dolor in repre
                                            henderit in voluptate velit esse cillum dolore 1.
                                        </p>
                                        <ul className="ps-3 fw-bold ul-marker">
                                            <li className="my-1 my-md-1">consectetur elit sed do eius</li>
                                            <li className="my-1 my-md-1">consectetur elit sed do eius</li>
                                            <li className="my-1 my-md-1">consectetur elit sed do eius</li>
                                        </ul>
                                        <div className="d-flex justify-content-center justify-content-md-start align-items-center">
                                            <button className='px-5 mb-2 mt-4 py-md-2 py-2 px-md-5 fw-bold button-font secondaryButton'>Read More</button>
                                        </div>
                                    </div>
                                }
                                {
                                    toggleState === 2 &&
                                    <div>
                                        <p className="my-4 mobile-responsive-text">
                                            Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et
                                            dolore magna aliqua enim ad minim veniam quis nostrud exercitation ulac
                                            laboris aliquip ex ea commodo consequat duis aute irure.dolor in repre
                                            henderit in voluptate velit esse cillum dolore 2.
                                        </p>
                                        <ul className="ps-3 fw-bold ul-marker">
                                            <li className="my-1 my-md-1">consectetur elit sed do eius</li>
                                            <li className="my-1 my-md-1">consectetur elit sed do eius</li>
                                            <li className="my-1 my-md-1">consectetur elit sed do eius</li>
                                        </ul>
                                        <div className="d-flex justify-content-center justify-content-md-start align-items-center">
                                            <button className='px-5 mb-2 mt-4 py-md-2 py-2 px-md-5 fw-bold button-font secondaryButton'>Read More</button>
                                        </div>
                                        {/* <Button variant="warning" style={{ width: '200px', fontWeight: '600' }}>Read More</Button>{' '} */}
                                    </div>
                                }
                                {
                                    toggleState === 3 &&
                                    <div>
                                        <p className="my-4 mobile-responsive-text">
                                            Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et
                                            dolore magna aliqua enim ad minim veniam quis nostrud exercitation ulac
                                            laboris aliquip ex ea commodo consequat duis aute irure.dolor in repre
                                            henderit in voluptate velit esse cillum dolore 3.
                                        </p>
                                        <ul className="ps-3 fw-bold ul-marker">
                                            <li className="my-1 my-md-1">consectetur elit sed do eius</li>
                                            <li className="my-1 my-md-1">consectetur elit sed do eius</li>
                                            <li className="my-1 my-md-1">consectetur elit sed do eius</li>
                                        </ul>
                                        <div className="d-flex justify-content-center justify-content-md-start align-items-center">
                                            <button className='px-5 mb-2 mt-4 py-md-2 py-2 px-md-5 fw-bold button-font secondaryButton'>Read More</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-center align-items-center d-none d-md-block">
                            <div className="row d-flex justify-content-center align-items-center g-0" style={{ border: '2px solid #FFCE45' }}>
                                <div className="col-md-6">
                                    <img
                                        className="p-0 m-0"
                                        src={provisionImage0}
                                        alt=""
                                        height="232"
                                        // width="282"
                                        width="100%"

                                    />
                                    <img
                                        className="p-0"
                                        src={provisionImage2}
                                        alt=""
                                        height="253"
                                        // width="282"
                                        width="100%"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <img
                                        className="p-0"
                                        src={provisionImage1}
                                        alt=""
                                        height="306"
                                        // width="282"
                                        width="100%"
                                    />
                                    <img
                                        className="p-0"
                                        src={provisionImage3}
                                        alt=""
                                        height="180"
                                        // width="282"
                                        width="100%"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Provisions;