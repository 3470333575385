import React from 'react';
import Appointment from '../HomeComponents/Appointment/Appointment';
import BannerCarousel from '../HomeComponents/BannerCarousel/BannerCarousel';
import BestExperts from '../HomeComponents/BestExperts/BestExperts';
import BottomBanner from '../HomeComponents/BottomBanner/BottomBanner';
import Clients from '../HomeComponents/Clients/Clients';
import Provisions from '../HomeComponents/Provisions/Provisions';
import Services from '../HomeComponents/Services/Services';
import WorkingProcess from '../HomeComponents/WorkingProcess/WorkingProcess';
import Footer from '../Shared/Footer/Footer';
import NavigationBar from '../Shared/NavigationBar/NavigationBar';
import Review from '../Shared/Review/Review';
// import Navigation from '../Shared/Navigation/Navigation';

const HomePage = () => {
    return (
        <>
            {/* <Navigation /> */}
            <NavigationBar />
            <BannerCarousel />
            <Appointment />
            <Provisions />
            <Services />
            <WorkingProcess />
            {/* <Experts /> */}
            <BottomBanner />
            <Review />
            <BestExperts />
            <Clients />
            <Footer />
        </>
    );
};

export default HomePage;