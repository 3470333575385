import React from 'react';
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./ServicesTypes.css";

// import required modules
import { Pagination, Navigation } from "swiper";

import serviceType0 from '../../../assets/images/services-types/ui-ux-type0.png';
import serviceType1 from '../../../assets/images/services-types/ui-ux-type1.png';
import serviceType2 from '../../../assets/images/services-types/ui-ux-type2.png';
import { Link } from 'react-router-dom';

const ServicesTypes = () => {

    const clientsReview = [
        {
            id: 1,
            image: serviceType0,
            name: `Websites Design`,
            comments:
                `We design easy, striking and interactive yet functional mobile apps for Android and iOS devices that live up to the users’ experience.`,
        },
        {
            id: 2,
            image: serviceType1,
            name: `Mobile Apps Design`,
            comments:
                `We design easy, striking and interactive yet functional mobile apps for Android and iOS devices that live up to the users’ experience.`,
        },
        {
            id: 3,
            image: serviceType2,
            name: `SaaS Design`,
            comments:
                `We design easy, striking and interactive yet functional mobile apps for Android and iOS devices that live up to the users’ experience.`,
        },
        {
            id: 4,
            image: serviceType0,
            name: `ERP Software`,
            comments:
                `We design easy, striking and interactive yet functional mobile apps for Android and iOS devices that live up to the users’ experience.`,
        },
        {
            id: 5,
            image: serviceType1,
            name: `POS Software`,
            comments:
                `We design easy, striking and interactive yet functional mobile apps for Android and iOS devices that live up to the users’ experience.`,
        },
        {
            id: 6,
            image: serviceType2,
            name: `VAT Software`,
            comments:
                `We design easy, striking and interactive yet functional mobile apps for Android and iOS devices that live up to the users’ experience.`,
        },
    ];

    return (
        <>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-11 col-md-8">
                    <div className="row my-5 my-md-5">
                        <div className="col-12 col-md-12">
                            <h2 className="fw-bold text-center">Types of Services We Provide</h2>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-end align-items-center mb-5 mb-md-5">
                        <div className="col-6 col-md-3 d-flex justify-content-end">
                            <BsFillArrowLeftSquareFill className="button-previous me-4 me-md-5" />
                            {/* </div>
                        <div className="col-2 col-md-2 d-flex justify-content-end"> */}
                            <BsFillArrowRightSquareFill className="button-next" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <Swiper
                                // slidesPerView={2}
                                spaceBetween={10}
                                // navigation={{
                                //     clickable: true,
                                // }}
                                // Navigation arrows
                                navigation={{
                                    clickable: false,
                                    nextEl: '.button-next',
                                    prevEl: '.button-previous',
                                }}
                                modules={[Navigation, Pagination]}
                                className="mySwiper"
                                loop={false}
                                // navigation={true}
                                breakpoints={
                                    {
                                        // when window width is >= 480px
                                        480: {
                                            slidesPerView: 1,
                                        },
                                        // when window width is >= 640px
                                        767: {
                                            slidesPerView: 3,
                                        }
                                    }
                                }
                            >
                                {
                                    clientsReview.map((client, index) =>
                                        <SwiperSlide key={client.id}>
                                            <div className="row d-flex justify-content-around align-items-center mb-5">
                                                <div className="col-11 col-md-11 d-flex justify-content-center align-items-center">
                                                    <div className="row d-flex justify-content-center align-items-center">
                                                        <div className="col-12 col-md-12">
                                                            <div className="row">
                                                                <div className="col-12 col-md-12">
                                                                    <img className="" height="250" width="100%" src={client.image} alt="client" />
                                                                </div>
                                                            </div>
                                                            <div className="row px-2 py-3 px-md-2 py-md-3 shadow rounded mt-3 my-md-3">
                                                                <div className="col-12 col-md-12 py-2">
                                                                    <div className="row px-3">
                                                                        <div className="col-12">
                                                                            <h6 className="fw-bold text-start">{client.name}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row px-3 mt-4 mb-4">
                                                                        <div className="col-12">
                                                                            <p className="text-start types-card-text">{client.comments}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row d-flex justify-content-center align-items-center">
                                                                        <div className="col-12">
                                                                            <Link to="/">
                                                                                <button className="bordered-custom-button">Get Appointment</button>
                                                                            </Link>
                                                                            {/* <button className="primaryButton px-3 px-md-3 py-1 py-md-1 fs-6">Get Appointment</button> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServicesTypes;