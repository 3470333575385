import React from 'react';
import Clients from '../HomeComponents/Clients/Clients';
import Footer from '../Shared/Footer/Footer';
import NavigationBar from '../Shared/NavigationBar/NavigationBar';

import map from '../../assets/images/map.png';

import './ContactPage.css';

const ContactPage = () => {
    return (
        <>
            <NavigationBar />
            {/* contact banner */}
            <div className="row contact-banner">
                <div className="col-md-12 d-flex justify-content-center align-items-center">
                    <h2 className="text-white fw-bold">Contact Us</h2>
                </div>
            </div>

            {/* contact map and form */}
            <div className="row d-flex justify-content-center align-items-center mt-md-5">
                <div className="row col-md-8 col-11 d-flex flex-md-row flex-column-reverse">
                    <div className="col-12 col-md-6 mb-0 mt-md-5">
                        <div className="row gx-0">
                            <div className="col-12 col-md-12">
                                <h3 className="fw-bold mt-md-4">Contact With Us</h3>
                            </div>
                        </div>
                        <div className="row my-5">
                            <div className="col-12 col-md-12">
                                <img src={map} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-5 ms-auto my-5">
                        <div className="row gx-0">
                            <div className="col-12 col-md-12">
                                <h5 className="text-uppercase primaryText">Get in touch</h5>
                                <h3 className="fw-bold">Fill The Form Bellow</h3>
                            </div>
                            <div className="row mt-5 form-bg rounded">
                                <div className="col-12 col-md-12">
                                    <div className="row">
                                        <form className="contact-input-field p-3 px-md-5 py-md-4">
                                            <input type="text" placeholder="Name *" />
                                            <input type="email" placeholder="Email *" />
                                            <input type="text" placeholder="Phone Number *" />

                                            <label htmlFor="description" className="text-white py-2">Project Description</label>
                                            <textarea id="description" name="description" rows="5" cols="33"></textarea>
                                        </form>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 px-3 px-md-5">
                                            <button className="secondaryButton w-50 px-3 py-2" style={{ fontWeight: 600}}>Submit</button>
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col-12 px-3 ps-md-5">
                                            <p className="text-white" style={{ fontSize: '12px' }}>By sending this form I confirm that I have read and accept Intellectsoft <span className="secondaryText">Privacy Policy</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* mail address location */}

            <div className="row d-flex justify-content-center align-items-center py-5 my-5" style={{ backgroundColor: '#F5F5F5' }}>
                <div className="row col-md-8 col-11 d-flex flex-column flex-md-row justify-content-center align-items-center">
                    <div className="col-md-4 col-11 mb-5 text-center text-md-start">
                        <div className="row d-flex justify-content-center justify-content-md-start align-items-center mb-3 mb-md-5">
                            <div className="col-md-3 col-2">
                                <div className="text-white text-center contact-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-activity"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2 mb-md-3">
                            <div className="col-12">
                                <h5 className="fw-bold">Email Us</h5>
                            </div>
                        </div>
                        <div className="row pe-md-5">
                            <div className="col-12">
                                <p className="text-secondary">Email us for general queries, including marketing and partnership opportunities</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h6 className="primaryText">zayco2u@gmail.com</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-11 mb-5 text-center text-md-start">
                        <div className="row d-flex justify-content-center justify-content-md-start align-items-center mb-3 mb-md-5">
                            <div className="col-md-3 col-2">
                                <div className="text-white text-center contact-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-activity"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2 mb-md-3">
                            <div className="col-12">
                                <h5 className="fw-bold">Call Us</h5>
                            </div>
                        </div>
                        <div className="row pe-md-5">
                            <div className="col-12">
                                <p className="text-secondary">Call us to speak to a member of our team</p>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12">
                                <h6 className="primaryText">+8801916-009922</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-11 mb-md-4 text-center text-md-start">
                        <div className="row d-flex justify-content-center justify-content-md-start align-items-center mb-3 mb-md-5 mt-md-0">
                            <div className="col-md-3 col-2">
                                <div className="text-white text-center contact-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-activity"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2 mb-md-3">
                            <div className="col-12">
                                <h5 className="fw-bold">Head Office</h5>
                            </div>
                        </div>
                        <div className="row pe-md-5">
                            <div className="col-12">
                                <p className="text-secondary">18/6, Mohammadia Housing Limited (main road), Mohammadpur, Dhaka-1207, Bangladesh.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h6 className="primaryText">Sunday – Friday : 2pm – 10pm</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Clients />
            <Footer />
        </>
    );
};

export default ContactPage;