import React, { useState } from 'react';

import { BsFacebook } from "react-icons/bs";
import { FaMinus, FaPlus } from 'react-icons/fa';
// import { TiSocialLinkedinCircular } from "react-icons/ti";
import { AiFillTwitterCircle } from "react-icons/ai";
import { TiSocialLinkedin } from "react-icons/ti";

import './Footer.css';

import logo from './../../../assets/images/zay-corporation.svg';
import facebookIcon from '../../../assets/images/social-icons/facebook.svg';
import linkedInIcon from '../../../assets/images/social-icons/linkedin.svg';
import whatsAppIcon from '../../../assets/images/social-icons/whatsapp.svg';
import gmailIcon from '../../../assets/images/social-icons/gmail.svg';

import footerPlay from '../../../assets/images/social-icons/footerPlay.svg';
import footerPlayColoured from '../../../assets/images/social-icons/footerPlayColoured.svg';

import location from '../../../assets/images/social-icons/location.svg';
import phone from '../../../assets/images/social-icons/phone.svg';
import mail from '../../../assets/images/social-icons/mail.svg';
import { Link } from 'react-router-dom';

const Footer = () => {

    const [clicked, setClicked] = useState("0");

    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked("0");
        }
        setClicked(index);
    };

    // const clickToTop = () => {
    //     // console.log('clicked')
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'smooth'
    //         /* you can also use 'auto' behaviour
    //            in place of 'smooth' */
    //     });
    // };

    const topics = [
        {
            id: 1,
            title: `Menu`,
            items: [
                {
                    id: 1,
                    name: `Home`,
                    icon: footerPlay,
                    icon2: footerPlayColoured,
                    link: `home`,
                },
                {
                    id: 2,
                    name: `Services`,
                    icon: footerPlay,
                    icon2: footerPlayColoured,
                    link: ``,
                },
                {
                    id: 3,
                    name: `About`,
                    icon: footerPlay,
                    icon2: footerPlayColoured,
                    link: `about_us`,
                },
                {
                    id: 4,
                    name: `Success`,
                    icon: footerPlay,
                    icon2: footerPlayColoured,
                    link: `success`,
                },
                {
                    id: 5,
                    name: `Contact`,
                    icon: footerPlay,
                    icon2: footerPlayColoured,
                    link: `contact`,
                },
            ],
        },
        {
            id: 2,
            title: `IT Services`,
            items: [
                {
                    id: 1,
                    name: `UI/UX Design`,
                    icon: footerPlay,
                    icon2: footerPlayColoured,
                    link: `ui_ux_design`,
                },
                {
                    id: 2,
                    name: `Manged IT Service`,
                    icon: footerPlay,
                    icon2: footerPlayColoured,
                    link: `managed_it_services`,
                },
                {
                    id: 3,
                    name: `Multi-Function Services`,
                    icon: footerPlay,
                    icon2: footerPlayColoured,
                    link: `app_development`,
                },
                {
                    id: 4,
                    name: `Web Development`,
                    icon: footerPlay,
                    icon2: footerPlayColoured,
                    link: `web_development`,
                },
                {
                    id: 5,
                    name: `Software Development`,
                    icon: footerPlay,
                    icon2: footerPlayColoured,
                    link: `software_development`,
                },
                {
                    id: 6,
                    name: `Analytic Solutions`,
                    icon: footerPlay,
                    icon2: footerPlayColoured,
                    link: `enterprise_solutions`,
                },
            ],
        },
        {
            id: 3,
            title: `Contact Info`,
            items: [
                {
                    id: 1,
                    name: `House - 18/6, Mohammadia Housing (Main Rd), Mohammadpur, Dhaka 1207, Bangladesh.`,
                    icon: location,
                    link: ``,
                },
                {
                    id: 2,
                    name: `+880 1916-009922`,
                    icon: phone,
                    link: ``,
                },
                {
                    id: 3,
                    name: `zayco2u@gmail.com`,
                    icon: mail,
                    link: ``,
                },
            ],
        },
    ];

    return (
        <>
            <div className="row g-0 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#222222" }}>
                <div className="col-md-8 col-11 text-white text-start">
                    <div className="row g-4">
                        <div className="col-md-12 col-12 d-flex flex-column align-items-center">
                            <div className="row g-0">
                                <div className="col-12 ">
                                    <div className=" footer-text-margin">
                                        <div className="row g-0">
                                            <div className="col-12 col-md-12 d-flex flex-column align-items-center">
                                                <Link to="/">
                                                    <img
                                                        src={logo}
                                                        alt="logo"
                                                        className="img-fluid footer-logo"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="row d-flex flex-column align-items-center g-0 mt-4">
                                            <div className="col-12 col-md-12 d-flex flex-column align-items-center">
                                                <p className="text-center footer-small-text">
                                                    Zay Corporation Limited is a private software development company who is working together with businesses to deal with the complexity of software development & cater solutions with excellence in both locally & globally since 2021. Our mission is “To create success & happiness for our customers & employees with our passion for technology while creating high growth opportunity of fulfilling employment thus serving humanity”.
                                                    {/* Zay Corporation Limited is a private software development company providing state-of-the-art software & IT solutions for fintech, telco, E-commerce, pharma, manufacturing, retail, etc. industries both locally & globally since 2021. Our mission is “To create success & happiness for our customers & employees with our passion for technology while creating high growth opportunity of fulfilling employment thus serving humanity." Currently, we are providing Software & IT solutions to over 7+ countries with active partners in- Bangladesh, Germany, USA, Australia, Japan, Dubai, China, India. We offer the widest range of technology stack with the most advanced technology solutions. We work together with businesses to deal with the complexity of software development & cater solutions with excellence. */}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row g-0 mt-3 mt-md-3 d-flex flex-column align-items-center">
                                            <div className="col-6 col-md-4 d-flex justify-content-between align-items-center">
                                                <div className="fs-2 me-2 me-md-0">
                                                    <BsFacebook className="d-none p-0" />
                                                    <a href="https://www.facebook.com/zayco4u" target="_blank" rel="noopener noreferrer" className="">
                                                        <img src={facebookIcon} alt="facebook" className="img-fluid" />
                                                    </a>
                                                </div>
                                                <div className="fs-2 me-2 me-md-0">
                                                    <TiSocialLinkedin className="d-none bg-white text-dark" style={{ borderRadius: '50%' }} />
                                                    <a href="https://www.linkedin.com/company/zay-corporation" target="_blank" rel="noopener noreferrer">
                                                        <img src={linkedInIcon} alt="linkedIn" className="img-fluid" />
                                                    </a>
                                                </div>
                                                <div className="fs-2 me-2 me-md-0">
                                                    <AiFillTwitterCircle className="d-none" />
                                                    <a href="https://wa.me/+8801916009922" target='_blank' rel="noopener noreferrer">
                                                        <img src={whatsAppIcon} alt="whatsApp" className="img-fluid" />
                                                    </a>
                                                </div>
                                                <div className="fs-2 me-2 me-md-0">
                                                    <a href="mailto:zayco2u@gmail.com" target="_blank" rel="noopener noreferrer">
                                                        <img src={gmailIcon} alt="gmail" className="img-fluid" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* desktop view */}
            <div className="row g-0 d-flex justify-content-center align-items-center pb-md-3" style={{ backgroundColor: "#222222" }}>
                <div className="col-md-8 col-11 text-white text-start">
                    <div className="row">
                        {
                            topics?.map((topic, index) =>
                                <div key={topic.id} className="col-md-4 col-3 d-none d-md-block">
                                    <div className="row g-0 d-flex flex-column align-items-center">

                                        <div className="col-12">
                                            <h6 className="mb-4 footer-text-margin"><span className="title-border"></span>{topic.title}</h6>
                                            {
                                                topic?.items.map((item, index) =>
                                                    <div key={item.id} className={(item.icon === mail || item.icon === phone) ? "row d-flex justify-content-around align-items-start mt-md-3" : "row d-flex justify-content-around align-items-center mt-md-1 play-icon"}>
                                                        {
                                                            item?.link ?
                                                                <div className="col-12 col-md-1">
                                                                    <img src={item.icon} alt="play-icon" className="regular-icon" />
                                                                    <img src={item.icon2} alt="play-icon" className="hovered-icon" />
                                                                </div>
                                                                :
                                                                <div className="col-12 col-md-1">
                                                                    <img src={item.icon} alt="play-icon" className="" />
                                                                </div>
                                                        }
                                                        <div className={item.icon === footerPlay ? "col-12 col-md-11 linked-menu" : "col-12 col-md-10"}>
                                                            {
                                                                item?.link ?
                                                                    <Link to={`/${item?.link}`}>
                                                                        <small className="footer-small-text">{item?.name}</small>
                                                                    </Link>
                                                                    :
                                                                    <small className="footer-small-text">{item?.name}</small>
                                                            }

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                    </div>
                                </div>
                            )
                        }

                        {/* mobile view */}
                        <div className="d-block d-md-none">
                            <div className="row my-3 my-md-5">
                                <div className="col-12 col-md-12">
                                    <ul className="footer-accordion">
                                        {topics.map((topic, index) => (
                                            <li key={topic.id} className={`footer-accordion-item ${clicked === index ? "active" : ""}`}>
                                                <button className="title footer-accordion-button" onClick={() => handleToggle(index)}>
                                                    {topic.title}
                                                    <span className="footer-control">{clicked === index ? <FaMinus style={{ color: '#FFCE45' }} /> : <FaPlus style={{ color: '#03A17B' }} />}</span>
                                                </button>
                                                <div className={`footer-answer-wrapper ${clicked === index ? "open" : ""}`}>
                                                    {
                                                        topic?.items.map((item, index) =>
                                                        // <div className="item">{item.name}</div>
                                                        (
                                                            item.icon === footerPlay ?
                                                                <div key={item.id} className="item mobile-linked-menu">
                                                                    {
                                                                        item?.link ?
                                                                            <Link to={`/${item?.link}`}>
                                                                                <span className="">{item.name}</span>
                                                                            </Link>
                                                                            :
                                                                            <span>{item.name}</span>
                                                                    }
                                                                </div>
                                                                :
                                                                <div key={item.id} className="row d-flex justify-content-center align-items-center my-3">
                                                                    <div className="col-2 my-1">
                                                                        <img src={item.icon} alt="icon" className="" />
                                                                    </div>
                                                                    <div className="col-10">
                                                                        <small className="footer-small-text">{item?.name}</small>
                                                                    </div>
                                                                </div>
                                                        )

                                                        )
                                                    }
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row d-flex justify-content-center align-items-center" style={{ backgroundColor: "#222222" }}>
                <hr
                    style={{ height: 3, color: "#565656" }}
                />
                <div className="col-11 col-md-8">
                    <div className="row g-0 text-white my-0 pb-2">
                        <div className="col-12 col-md-7 d-flex justify-content-center justify-content-md-start align-items-center ms-0">
                            <small className="text-center text-md-center footer-credit-text"> &copy; {(new Date()).getFullYear()} All rights reserved. Developed by <span className="fw-bold">Zay Corporation</span> </small>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;