import React from 'react';
import Footer from '../Shared/Footer/Footer';
import NavigationBar from '../Shared/NavigationBar/NavigationBar';

import './CareerPage.css';

const CareerPage = () => {
    return (
        <>
           <NavigationBar/>
           <div className="row d-flex justify-content-center align-items-center mt-md-5">
                <div className="col-11 col-md-8 mb-5 my-md-5">
                    <div className="row">
                        <h3 className="fw-bold text-center">Current Job Openings</h3>
                    </div>
                </div>
                <div className="col-11 col-md-8 my-md-3 px-md-0">
                    <div className="row">
                        <p className="text-start mobile-responsive-text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facilis accusantium totam, corporis sint fuga modi fugit distinctio! Perferendis expedita impedit delectus quisquam in amet perspiciatis vero eos. Minus dolorum, magnam consequatur autem quae, a pariatur tempora quas sit iure obcaecati.</p>
                    </div>
                </div>
                <div className="row gx-md-0 my-3 my-md-5 col-11 col-md-8 my-md-3 d-flex justify-content-md-between align-items-md-center">
                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <h6 className="text-start primaryText">We currently have no job openings</h6>
                    </div>
                    <div className="col-12 col-md-6 mb-5 mb-md-0 text-start text-md-end">
                        <h4 className="fw-bold">For questions call us</h4>
                        <h4 className="fw-bold secondaryText">+8801916-009922</h4>
                    </div>
                </div>
            </div> 
            <Footer />
        </>
    );
};

export default CareerPage;