import React from 'react';
// import { Button } from 'react-bootstrap';
import bottomBackgroundImage from './../../../assets/images/bottom-backgroundImage.png';
import rightArrow from '../../../assets/images/bottom-banner-right-arrow.svg';

import './BottomBanner.css';
import { Link } from 'react-router-dom';

const BottomBanner = () => {
    return (
        <>
            <div className="row d-flex justify-content-center align-items-center my-5 pb-5">
                <div className="col-12 col-md-12">
                    <img
                        className="d-block"
                        src={bottomBackgroundImage}
                        alt="bottomBackground"
                        height="100%"
                        width="100%"
                    />
                </div>
                {/* style={{ backgroundColor: "#FFCE45", height: 344, marginTop: "-150px" }} */}
                <div className="col-9 col-md-6 d-flex justify-content-center align-items center shadow-lg rounded p-md-5 p-4 banner-margin" style={{ backgroundColor: "#FFCE45", marginTop: "-9%" }}>
                    <div className="row">
                        <div className="col-12 col-md-12 d-flex flex-column justify-content-center align-items-center">
                            <h2 className="fw-bold text-center bottom-banner-text">Over a decade of steller <br className="d-none d-md-block" /> software solutions</h2>
                            <Link to="/contact">
                                <button className="bottom-banner-button mt-md-4 mt-3"><span className="me-3">Talk to us</span>
                                    <img src={rightArrow} alt="rightArrow" className="" />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="bottom-contact my-5">
                <div className="row">
                    <div className="col-md-12">
                        <img
                            className="d-block mt-5"
                            src={bottomBackgroundImage}
                            alt="bottomBackground"
                            height="100%"
                            width="100%"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mx-auto rounded d-flex justify-content-center align-items-center shadow-lg" style={{ backgroundColor: "#FFCE45", height: 344, marginTop: "-150px" }}>
                        <div>
                            <h2 className="fw-bold">Over a decade of steller <br /> software solutions</h2>
                            <Button variant="success" className="mt-4 px-5">Talk to us</Button>{' '}
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default BottomBanner;