import React from 'react';
import BottomBanner from '../HomeComponents/BottomBanner/BottomBanner';
import Clients from '../HomeComponents/Clients/Clients';
import Footer from '../Shared/Footer/Footer';
import NavigationBar from '../Shared/NavigationBar/NavigationBar';
import Review from '../Shared/Review/Review';

import './ManagedItPage.css';

import ITservice0 from '../../assets/images/ITservice0.png';
import ITservice1 from '../../assets/images/ITservice1.png';

const ManagedItPage = () => {
    return (
        <>
            <NavigationBar />
            
            {/* infrastructure section */}
            <section>
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-11 col-md-8 my-md-5 mb-5">
                        <div className="row d-flex justify-content-center align-items-center my-md-5 mb-5">
                            <div className="col-12 col-md-6">
                                <h3 className="fw-bold text-center text-md-center">Managed IT Services</h3>
                            </div>
                        </div>
                        <div className="row d-flex flex-column-reverse flex-md-row justify-content-center align-items-center">
                            <div className="col-12 col-md-6">
                                <img src={ITservice0} alt="officeEmployee" className="img-fluid" />
                            </div>
                            <div className="col-12 col-md-5 text-secondary ms-auto my-auto mb-3 mb-md-0">
                                <p className="mobile-responsive-text">Managed IT services are a way to keep your IT infrastructure and applications stable, cost-effective, and relevant to the evolving business needs.</p>
                                <p className="mobile-responsive-text">An IT company of 5 years, Zayco is a reliable provider of remote monitoring, management and evolution services for on-premises and cloud infrastructures and help desk services.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center align-items-center pb-5 pb-md-0" style={{ backgroundColor: '#F5F5F5' }}>
                    <div className="col-11 col-md-8 my-md-5 my-3">
                        <div className="row d-flex justify-content-center align-items-center py-5 py-md-0 my-md-3">
                            <div className="col-12 col-md-6">
                                <h3 className="fw-bold text-center text-md-center">IT Infrastructure Components Our Managed IT Services Tackle</h3>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center align-items-center mb-4 mb-md-0">
                            <div className="col-12 col-md-12 text-secondary">
                                <p className="mobile-responsive-text text-center">Zayco can handle all daily monitoring and management and/or long-term optimization and evolution of your IT infrastructure components. We take care of the entire IT infrastructure as a whole or any of its specific parts.</p>
                            </div>
                        </div>
                        <div className="row my-md-5 py-md-3">
                            <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
                                <img src={ITservice1} alt="officeEmployee" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* choosing reasons section */}
            <section>
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-11 col-md-8 my-md-5 mt-3 mb-5">
                        <div className="row mt-5 mb-3 mt-md-0 mb-md-5 d-flex justify-content-center align-items-center">
                            <div className="col-12 col-md-6">
                                <h3 className="fw-bold text-center text-md-center">Why Choose ZayCo</h3>
                            </div>
                        </div>
                        <div className="row reasons">
                            <div className="col-12 col-md-12">
                                <ul className="mobile-responsive-text">
                                    <li>5 years in IT services.</li>
                                    <li>3 years of experience in ITSM.</li>
                                    <li>ITIL personnel holding ITSM-related certificates.</li>
                                    <li>More than 8 years in applying DevOps practices.</li>
                                    <li>More than 700 IT specialists including Microsoft Certified Professionals, AWS Certified Solutions Architects, AWS Certified SysOps Administrators, AWS Technical Professionals, Red Hat Certified System Administrators.</li>
                                    <li>55% of our revenue comes from 1+year-long engagements.</li>
                                    <li>Mature quality management system confirmed by the ISO 9001 certification that proves our ability to implement projects fully meeting customers’ quality, time, and budget expectations.</li>
                                    <li>Assured safety of customers’ data we access during the cooperation proved by ISO 27001 certificate.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Review />
            <BottomBanner />
            <Clients />
            <Footer />
        </>
    );
};

export default ManagedItPage;