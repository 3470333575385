import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { BsArrowRight } from "react-icons/bs";

import './Services.css';

import FadedImageSlider from '../../Shared/FadedImageSlider/FadedImageSlider';

const Services = () => {

    const services = [
        {
            id: 1,
            title: `UI / UX Design`,
            description: `1Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ulac laboris aliquip ex ea commodo consequat duis aute irure.dolor in repre henderit in voluptate velit esse cillum dolore. Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis.`,
        },
        {
            id: 2,
            title: `Web Development`,
            description: `2Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ulac laboris aliquip ex ea commodo consequat duis aute irure.dolor in repre henderit in voluptate velit esse cillum dolore. Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis.`,
        },
        {
            id: 3,
            title: `App Development`,
            description: `3Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ulac laboris aliquip ex ea commodo consequat duis aute irure.dolor in repre henderit in voluptate velit esse cillum dolore. Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis.`,
        },
        {
            id: 4,
            title: `Software Development`,
            description: `4Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ulac laboris aliquip ex ea commodo consequat duis aute irure.dolor in repre henderit in voluptate velit esse cillum dolore. Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis.`,
        },
        {
            id: 5,
            title: `Enterprise Solutions`,
            description: `5Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ulac laboris aliquip ex ea commodo consequat duis aute irure.dolor in repre henderit in voluptate velit esse cillum dolore. Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis.`,
        },
        {
            id: 6,
            title: `Managed IT Services`,
            description: `6Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ulac laboris aliquip ex ea commodo consequat duis aute irure.dolor in repre henderit in voluptate velit esse cillum dolore. Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis.`,
        },
    ];

    const [toggleState, setToggleState] = useState(
        {
            id: 1,
            title: `UI / UX Design`,
            description: `1Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis nostrud exercitation ulac laboris aliquip ex ea commodo consequat duis aute irure.dolor in repre henderit in voluptate velit esse cillum dolore. Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore et dolore magna aliqua enim ad minim veniam quis.`,
        }
    );

    return (
        <>
            <div className="py-5" style={{ backgroundColor: "#F5F5F5" }}>
                <div className="row d-flex justify-content-center align-items-center mb-4">
                    <div className="col-md-8 col-12">
                        <h2 className="fw-bold text-center">Our Services</h2>
                    </div>
                </div>

                {/* desktop view */}

                <div className="row d-none d-md-block">
                    <div className="nav-container col-11 col-md-8">
                        <div className="ps-4 pe-4" style={{ backgroundColor: "#FFCE45" }}>
                            {/* <Row xs={0} md={6} className="gx-0"> */}
                            <div className="d-flex justify-content-between align-items-center">
                                {
                                    services.map((service, index) =>
                                        // <Col className={toggleState.id === service.id ? "tabs" : "tabs"}
                                        //     onClick={() => setToggleState(service)}
                                        // >
                                        <p key={service.id} className={toggleState.id === service.id ? "active-tabs" : "tabs"}
                                            onClick={() => setToggleState(service)}
                                            style={{ paddingBottom: 0 }}
                                        >
                                            {service.title}
                                            {/* <span className={toggleState.id === service.id && "active-tabs"} >{service.title}</span> */}
                                        </p>
                                        // </Col>
                                    )
                                }
                            </div>
                            {/* </Row> */}
                        </div>
                        <div className="content-tabs d-none d-md-block">
                            <div className={"row g-0 mt-4 content  active-content"}>
                                <div className="col-md-8">
                                    <div className="d-flex justify-content-center flex-column">
                                        <div>
                                            <h4 className="fw-bold my-3">{toggleState.title}</h4>
                                            <p className="me-0">
                                                {toggleState.description}
                                            </p>
                                            <button className='px-2 my-2 py-md-2 p-1 px-md-5 fw-bold button-font secondaryButton'>Read More</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <FadedImageSlider />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* mobile view */}
                <div className="row mobile-content d-md-none d-block d-flex justify-content-center align-items-center">
                    <div className="col-11">
                        <Row xs={2} md={0} className="g-3">
                            {
                                services?.map((service, index) => (
                                    <Col key={service.id} className="my-2">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className={toggleState.id === service.id ? "active-title col-11 border-2 rounded" : "col-11 bg-white border-2 rounded"}>
                                                <h6 onClick={() => setToggleState(service)} className="text-center services-title py-3 my-auto">{service.title}</h6>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                        <div className="row mt-3 d-flex justify-content-center align-items-center single-service">
                            <div className="col-12 border-2 rounded bg-white shadow">
                                <div className="row p-3">
                                    <div className="col-12">
                                        <p className="">{toggleState?.description}</p>
                                    </div>
                                    <div className="col-12 d-flex justify-content-end align-items-end">
                                        <button className="single-service-button">Read More <BsArrowRight /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Services;