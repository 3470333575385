// import React from 'react';

import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import client0 from './../../../assets/images/clients/ourClients/hrtgroup.png';
import client1 from './../../../assets/images/clients/ourClients/rafaconstruction.png';
import client2 from './../../../assets/images/clients/ourClients/rcl logo.png';
import client3 from './../../../assets/images/clients/ourClients/vapeworlduae.png';
import client4 from './../../../assets/images/clients/ourClients/JTC_logo.jpg';

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";

import "./Clients.css";

// import required modules
import { Autoplay, Mousewheel, Keyboard } from "swiper";

const Clients = () => {
    return (
        <>
            <div className="row d-flex justify-content-center align-items-center bg-white my-5">
                <div className="col-11 col-md-8">
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <h2 className="fw-bold pb-3 text-center">Our Clients</h2>
                        </div>
                    </div>
                    <div className="row" style={{ backgroundColor: '#fff' }}>
                        <div className="col-12 col-md-12">
                            <Swiper
                                // slidesPerView={3}
                                // spaceBetween={20}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Autoplay, Mousewheel, Keyboard]}
                                className="mySwiper"
                                autoplay={true}
                                autoplaySpeed={1000}
                                infinite={true}
                                loop={true}
                                breakpoints={
                                    {
                                        // when window width is >= 320px
                                        320: {
                                            slidesPerView: 2,
                                            spaceBetween: 30,
                                        },
                                        // when window width is >= 767px
                                        767: {
                                            slidesPerView: 3,
                                        }
                                    }
                                }
                            >
                                <SwiperSlide>
                                    <img height='200' width="200" className="img-fluid" src={client0} alt="client" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img height='150' width="150" className="img-fluid" src={client1} alt="client" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img height='200' width="200" className="img-fluid" src={client2} alt="client" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img height='200' width="200" className="img-fluid" src={client3} alt="client" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img height='100' width="100" className="img-fluid" src={client4} alt="client" />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Clients;